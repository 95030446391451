import Default from "layouts/dashboards/default";
import Shop from "examples/Icons/Shop";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Reports from "layouts/pages/users/reports";

const routes = [
  {
    type: "collapse",
    name: "Leads",
    key: "leads-pages",
    icon: <Shop size="12px" />,
    collapse: [
      // {
      //   name: "New Lead",
      //   key: "new",
      //   route: "pages/leads/new",
      //   component: <Default />,
      // },
      {
        name: "Fresh Leads",
        key: "fresh",
        route: "pages/leads/fresh",
        component: <Default />,
      },
      {
        name: "Contacted Leads",
        key: "contacted",
        route: "pages/leads/contacted",
        component: <Default />,
      },
      {
        name: "Applied Leads",
        key: "applied",
        route: "pages/leads/applied",
        component: <Default />,
      },
      {
        name: "Closed Leads",
        key: "closed",
        route: "pages/leads/closed",
        component: <Default />,
      },
      {
        name: "Lost Leads",
        key: "lost",
        route: "pages/leads/lost",
        component: <Default />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Loan Packages",
    key: "packages-pages",
    icon: <CreditCard size="12px" />,
    collapse: [
      {
        name: "Manage",
        key: "manage",
        route: "pages/loan-packages/manage-package-names",
        component: <Default />,
      },
      {
        name: "All",
        key: "all",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "DBS",
        key: "dbs",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "UOB",
        key: "uob",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "OCBC",
        key: "ocbc",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "Citibank",
        key: "citi",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "SCB",
        key: "scb",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "MayBank",
        key: "mb",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "CIMB",
        key: "cimb",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "Hong Leong",
        key: "hl",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "SBI",
        key: "sbi",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "RHB",
        key: "rhb",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
      {
        name: "HSBC",
        key: "hsbc",
        route: "pages/loan-packages/all",
        component: <Default />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users-pages",
    icon: <CustomerSupport size="12px" />,
    collapse: [
      {
        name: "Bankers",
        key: "bankers",
        route: "/users/reports/bankers",
        component: <Reports />,
      },
      {
        name: "Consultants",
        key: "consultants",
        route: "/users/reports/consultants",
        component: <Reports />,
      },
      {
        name: "Admins",
        key: "admins",
        route: "/users/reports/superadmins",
        component: <Reports />,
      },
    ],
  },
];

export default routes;
