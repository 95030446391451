import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';

import {
    navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";

const LogoutButton = ({light}) => {
  const { logout } = useAuth0();

  const logout_func = () => logout({ logoutParams: { returnTo: window.location.origin } });

  return (
    <Tooltip title="Sign Out" arrow>
        <IconButton
            size="small"
            color="inherit"
            sx={navbarIconButton}
            onClick={logout_func}
            >
            <LogoutIcon className={light ? "text-white" : "text-dark"} />
        </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;