import React from "react";
import "../assets/css/spinner.css";
const Loading = () => (
  <div 
    id="notificationModal"
    style={{
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        transition: 'opacity 1s',
        background: 'rgba(0, 0, 0, 0.9)',
        padding: '20px',
        borderRadius: '10px',
        width: '100%',  
        height: '100%'
    }}
    >
      <div className="spinner-container" style={{margin: "0 auto", alignContent: "center", justifyContent: "center"}}>
        <div className="loading-spinner">
        </div>
      </div>
</div>
);

export default Loading;