import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { SoftUIControllerProvider } from "context";

//Authentication
import { Auth0Provider } from "@auth0/auth0-react";

import { getConfig } from "./config";

const root = ReactDOM.createRoot(document.getElementById("root"));

const config = getConfig();

const providerConfig = {
  debug: true,
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: "localstorage",
  scope: "openid profile email offline_access",
  audience: "loansupermart-lontong",
  useRefreshTokens: true,
  authorizationParams: {
    // connection: "google-oauth2",
    redirect_uri: `${window.location.origin}/dashboards/crm`,
  },
};

root.render(
  <Auth0Provider {...providerConfig}>
    <BrowserRouter>
      <SoftUIControllerProvider>
        <App />
      </SoftUIControllerProvider>
    </BrowserRouter>
  </Auth0Provider>
);
