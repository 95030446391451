const Banks = [
  { value: "DBS", label: "DBS" },
  { value: "UOB", label: "UOB" },
  { value: "OCBC", label: "OCBC" },
  { value: "Citibank", label: "Citibank" },
  { value: "SCB", label: "SCB" },
  { value: "MayBank", label: "MayBank" },
  { value: "CIMB", label: "CIMB" },
  { value: "Hong Leong", label: "Hong Leong" },
  { value: "SBI", label: "SBI" },
  { value: "RHB", label: "RHB" },
  { value: "HSBC", label: "HSBC" },
  { value: "BOC", label: "BOC" },
];

export default Banks;
