/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Google";
import Separator from "layouts/authentication/components/Separator";

// authentication
import { useAuth0 } from "@auth0/auth0-react";

// Images
import family from "assets/images/loan-supermart-4-scaled.jpg";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const { loginWithRedirect } = useAuth0();

  return (
    <BasicLayout
      title="Loan$upermart"
      description="Login to the CRM"
      image={family}
    >
      <Card>
        <SoftButton
          variant="gradient"
          color="info"
          onClick={() => loginWithRedirect()}
          fullWidth
        >
          sign in
        </SoftButton>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
