const Benchmarks = [
  { value: null, label: "" },
  // {value: "1msora", label: "1-month Compounded SORA"},
  // {value: "3msora", label: "3-months Compounded SORA"},
  // {value: "6msora", label: "6-months Compounded SORA"},
  // {value: "6mfdhr", label: "6-months Fixed Deposit Home Rates(FDHR6)"},
  // {value: "bbr", label: "Bank's Board Rate"},
  // {value: "36mfdr", label: "36-months Fixed Deposit Rates(FDR36)"},
];

export default Benchmarks;
