import configJson from "./auth_config.json";

export function getConfig() {

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    storageType: configJson.storageType,
    scope: configJson.scope,
    audience: configJson.audience,
    conn: configJson.conn,
    redirect_uri: configJson.redirect_uri
  };
}
