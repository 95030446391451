import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import Table from "examples/Tables/PackageNameTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import Pagination from "@mui/material/Pagination";
import * as api from "api";

const columns = [
  { name: "id", align: "left" },
  { name: "name", align: "left" },
];

const ManagePackageNames = () => {
  const [packageNames, setPackageNames] = useState([]);
  const [packageNamesPerPage, setPackageNamesPerPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const rowsPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    getPackageNames(currentPage);
  }, [currentPage]);

  const getPackageNames = async (page) => {
    try {
      const data = await api.getPackageNames();
      console.log("API Response:", data);
      if (data) {
        setPackageNames(
          data.packageNames.map((item) => ({
            id: item.package_name_id,
            name: item.package_name,
          }))
        );
        setTotalPages(Math.ceil(data.packageNames.length / rowsPerPage));
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (packageNames.length > 0) {
      const startIndex = (currentPage - 1) * rowsPerPage;
      const endIndex = startIndex + rowsPerPage;
      const slicedPackageNames = packageNames.slice(startIndex, endIndex);
      setPackageNamesPerPage(slicedPackageNames);
    }
  }, [packageNames, currentPage, rowsPerPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox title="Manage Package Names">
        <SoftBox className="container">
          <SoftBox
            className="mt-4"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "1rem",
            }}
          >
            <SoftButton
              component={""}
              variant="gradient"
              color="info"
              onClick={() => navigate("add")}
            >
              Add
            </SoftButton>
          </SoftBox>
          <Table columns={columns} rows={packageNamesPerPage} />
        </SoftBox>
        <SoftBox
          sx={{
            marginTop: "5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
          />
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default ManagePackageNames;
