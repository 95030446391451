// src/layouts/pages/loan-packages/add/index.js
import { useEffect, useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Radio,
  Checkbox,
  RadioGroup,
  FormControl,
  FormLabel,
  Grid,
  Card,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import PackageNames from "data/PackageNames";
import Benchmarks from "data/Benchmarks";
import RatesTypes from "data/RatesTypes";
import PropertyTypes from "data/PropertyTypes";
import PackageTypes from "data/PackageTypes";
import Banks from "data/Banks";

import { sqlInjectionKeywords } from "commons/FormCleansing";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import NotificationModal from "components/NotificationModal";

import { apiURL } from "commons/apiURL";
import { lab } from "chroma-js";
import { match } from "stylis";

function AddPackage(props) {
  const user = props.user;
  const isLoading = props.isLoading;

  // States
  const [state, setState] = useState({
    showModal: false,
    isModalRedirect: true,
    checked: false,
    bank: null,

    selectedPackage: "",
    newPackageName: "",
    selectedPackageType: "",

    propertyType: "",
    propertyLocation: 1,

    year1RateType: null,
    year1RateValue: "",
    tempYear1RateValue: "",
    year1RatesBenchmark: null,

    year1Hybrid1RateValue: null,
    year1Hybrid1RatePortion: null,
    year1Hybrid2RateValue: null,
    year1Hybrid2Benchmark: null,
    year1Hybrid2BenchmarkText: null,
    year1Hybrid2RatePortion: null,

    year2RateType: "",
    year2RateValue: "",
    tempYear2RateValue: "",
    year2RatesBenchmark: null,

    year2Hybrid1RateValue: null,
    year2Hybrid1RatePortion: null,
    year2Hybrid2RateValue: null,
    year2Hybrid2Benchmark: null,
    year2Hybrid2BenchmarkText: null,
    year2Hybrid2RatePortion: null,

    year3RateType: null,
    year3RateValue: "",
    tempYear3RateValue: "",
    year3RatesBenchmark: null,

    year3Hybrid1RateValue: null,
    year3Hybrid1RatePortion: null,
    year3Hybrid2RateValue: null,
    year3Hybrid2Benchmark: null,
    year3Hybrid2BenchmarkText: null,
    year3Hybrid2RatePortion: null,

    year4RateType: null,
    year4RateValue: "",
    tempYear4RateValue: "",
    year4RatesBenchmark: "",

    year4Hybrid1RateValue: null,
    year4Hybrid1RatePortion: null,
    year4Hybrid2RateValue: null,
    year4Hybrid2Benchmark: null,
    year4Hybrid2BenchmarkText: null,
    year4Hybrid2RatePortion: null,

    year5RateType: null,
    year5RateValue: "",
    tempYear5RateValue: "",
    year5RatesBenchmark: null,

    year5Hybrid1RateValue: null,
    year5Hybrid1RatePortion: null,
    year5Hybrid2RateValue: null,
    year5Hybrid2Benchmark: null,
    year5Hybrid2BenchmarkText: null,
    year5Hybrid2RatePortion: null,

    thereafterRateType: null,
    thereafterRateValue: "",
    tempThereafterRateValue: "",
    thereafterRatesBenchmark: null,

    thereafterHybrid1RateValue: null,
    thereafterHybrid1RatePortion: null,
    thereafterHybrid2RateValue: null,
    thereafterHybrid2Benchmark: null,
    thereafterHybrid2BenchmarkText: null,
    thereafterHybrid2RatePortion: null,

    year1RatesBenchmarkText: "",
    year2RatesBenchmarkText: "",
    year3RatesBenchmarkText: "",
    year4RatesBenchmarkText: "",
    year5RatesBenchmarkText: "",
    thereafterRatesBenchmarkText: "",

    boardRateValue: "",
    tempBoardRateValue: "",

    lockInPeriod: 2,
    tempLockInPeriod: 2,
    penaltyForBreaking: "1.5",
    tempPenaltyForBreaking: "1.5",
    lockInPeriodDescription: 0,
    tempLockInPeriodDescription: 0,

    legalSubsidy: "",
    legalSubsidyAmount: "",
    tempLegalSubsidyAmount: "",
    legalSubsidyClawbackPeriod: "",
    tempLegalSubsidyClawbackPeriod: "",

    fireInsurance: "",

    freeValuationDescription: "",
    tempFreeValuationDescription: "",

    freeRepricing: "",
    monthsForRepricing: "",

    remarks: "",
    tempRemarks: "",

    features: "",
    tempFeatures: "",

    minLoanQuantum: "",
    tempMinLoanQuantum: "",

    buildingUnderConstruction: "",
    cancellationFee: "",

    creditCardApplication: "",
    openSavingsAccount: "",
    depositSavingsAccount: "",
    openCurrentAccount: "",
    depositCurrentAccount: "",
    openFixedDepositAccount: "",
    depositFixedDepositAccount: "",

    lockinSell: 0,
    monthsAllowedForSale: "",
    tempMonthsAllowedForSale: "",
    penaltyWaiver: "",
    tempPenaltyWaiver: "",

    partialPrepayment: "",
    partialPrepaymentYearsAllowed: "",
    partialPrepaymentPaymentAllowed: "",
    partialPrepaymentPenalty: "",
  });
  const [errors, setErrors] = useState({});

  const [packageNames, setPackageNames] = useState(PackageNames);
  const [benchmarks, setBenchmarks] = useState(Benchmarks);

  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();
  userRole = userRole.replace("BANKER-", "");

  const handleSwitchChange = (event) => {
    setState((state) => ({ ...state, checked: event.target.checked }));
  };

  const matchingOptionItem = (obj, value) => {
    return obj.find((option) => option.value === value);
  };

  const checkuserRole = (userRole) => {
    let result = Banks.find((option) => option.value === userRole);
    if (result === undefined) {
      return false;
    } else if (result.value === userRole) {
      return true;
    }
  };

  const clearRedundantFieldStates = () => {
    if (state.year1RateType !== "floating") {
      setState((state) => ({ ...state, year1RatesBenchmark: null }));
      setState((state) => ({ ...state, year1RatesBenchmarkText: null }));
    }
    if (state.year2RateType !== "floating") {
      setState((state) => ({ ...state, year2RatesBenchmark: null }));
      setState((state) => ({ ...state, year2RatesBenchmarkText: null }));
    }
    if (state.year3RateType !== "floating") {
      setState((state) => ({ ...state, year3RatesBenchmark: null }));
      setState((state) => ({ ...state, year3RatesBenchmarkText: null }));
    }
    if (state.year4RateType !== "floating") {
      setState((state) => ({ ...state, year4RatesBenchmark: null }));
      setState((state) => ({ ...state, year4RatesBenchmarkText: null }));
    }
    if (state.year5RateType !== "floating") {
      setState((state) => ({ ...state, year5RatesBenchmark: null }));
      setState((state) => ({ ...state, year5RatesBenchmarkText: null }));
    }
    if (state.thereafterRateType !== "floating") {
      setState((state) => ({ ...state, thereafterRatesBenchmark: null }));
      setState((state) => ({ ...state, thereafterRatesBenchmarkText: null }));
    }

    if (state.year1RateType !== "hybrid") {
      setState((state) => ({ ...state, year1Hybrid1RateValue: null }));
      setState((state) => ({ ...state, year1Hybrid1RatePortion: null }));
      setState((state) => ({ ...state, year1Hybrid2RateValue: null }));
      setState((state) => ({ ...state, year1Hybrid2RatePortion: null }));
      setState((state) => ({ ...state, year1Hybrid2Benchmark: null }));
      setState((state) => ({ ...state, year1Hybrid2BenchmarkText: null }));
    }

    if (state.year2RateType !== "hybrid") {
      setState((state) => ({ ...state, year2Hybrid1RateValue: null }));
      setState((state) => ({ ...state, year2Hybrid1RatePortion: null }));
      setState((state) => ({ ...state, year2Hybrid2RateValue: null }));
      setState((state) => ({ ...state, year2Hybrid2RatePortion: null }));
      setState((state) => ({ ...state, year2Hybrid2Benchmark: null }));
      setState((state) => ({ ...state, year2Hybrid2BenchmarkText: null }));
    }

    if (state.year3RateType !== "hybrid") {
      setState((state) => ({ ...state, year3Hybrid1RateValue: null }));
      setState((state) => ({ ...state, year3Hybrid1RatePortion: null }));
      setState((state) => ({ ...state, year3Hybrid2RateValue: null }));
      setState((state) => ({ ...state, year3Hybrid2RatePortion: null }));
      setState((state) => ({ ...state, year3Hybrid2Benchmark: null }));
      setState((state) => ({ ...state, year3Hybrid2BenchmarkText: null }));
    }

    if (state.year4RateType !== "hybrid") {
      setState((state) => ({ ...state, year4Hybrid1RateValue: null }));
      setState((state) => ({ ...state, year4Hybrid1RatePortion: null }));
      setState((state) => ({ ...state, year4Hybrid2RateValue: null }));
      setState((state) => ({ ...state, year4Hybrid2RatePortion: null }));
      setState((state) => ({ ...state, year4Hybrid2Benchmark: null }));
      setState((state) => ({ ...state, year4Hybrid2BenchmarkText: null }));
    }

    if (state.year5RateType !== "hybrid") {
      setState((state) => ({ ...state, year5Hybrid1RateValue: null }));
      setState((state) => ({ ...state, year5Hybrid1RatePortion: null }));
      setState((state) => ({ ...state, year5Hybrid2RateValue: null }));
      setState((state) => ({ ...state, year5Hybrid2RatePortion: null }));
      setState((state) => ({ ...state, year5Hybrid2Benchmark: null }));
      setState((state) => ({ ...state, year5Hybrid2BenchmarkText: null }));
    }

    if (state.thereafterRateType !== "hybrid") {
      setState((state) => ({ ...state, thereafterHybrid1RateValue: null }));
      setState((state) => ({ ...state, thereafterHybrid1RatePortion: null }));
      setState((state) => ({ ...state, thereafterHybrid2RateValue: null }));
      setState((state) => ({ ...state, thereafterHybrid2RatePortion: null }));
      setState((state) => ({ ...state, thereafterHybrid2Benchmark: null }));
      setState((state) => ({ ...state, thereafterHybrid2BenchmarkText: null }));
    }
  };

  const cleanForm = (value) => {
    return sqlInjectionKeywords.some((keyword) =>
      value.toUpperCase().includes(keyword.toUpperCase())
    );
  };

  const checkFloat = (value, decimal = 3) => {
    const parts = String(value).split(".");
    if (parts.length === 2 && parts[1].length > decimal) {
      return `Value must not have more than ${decimal} decimal places.`;
    } else if (!new RegExp(`^\\d+(\\.\\d{1,${decimal}})?$`).test(value)) {
      return `Value must be a valid number with up to ${decimal} decimal places.`;
    } else if (value.length > 30) {
      return "Value cannot exceed 30 characters.";
    } else {
      return null;
    }
  };

  const checkInt = (value) => {
    if (!/^\d+$/.test(value)) {
      return "Value must be a valid number. Decimals are not allowed.";
    } else if (value.length > 20) {
      return "Value cannot exceed 20 characters.";
    } else {
      return null;
    }
  };

  useEffect(() => {
    clearRedundantFieldStates();
  }, [
    state.year1RateType,
    state.year2RateType,
    state.year3RateType,
    state.year4RateType,
    state.year5RateType,
    state.thereafterRateType,
  ]);

  const validateFields = () => {
    let newErrors = {};

    if (!checkuserRole(userRole)) {
      if (!state.bank) {
        newErrors.bank = "Bank is required.";
      }
    }

    if (!state.selectedPackage || state.selectedPackage === "new") {
      newErrors.packageName = "Package Name is required.";
    }

    if (!state.selectedPackageType) {
      newErrors.packageType = "Package Type is required.";
    }

    if (!state.propertyType) {
      newErrors.propertyType = "Property Type is required.";
    }

    if (!state.year1RateType) {
      newErrors.year1RateType = "Year 1 Rate Type is required.";
    }
    if (state.year1RateType !== "hybrid" && !state.year1RateValue) {
      newErrors.year1RateValue = "Year 1 Rate Value is required.";
    } else if (state.year1RateValue) {
      if (checkFloat(state.year1RateValue) !== null) {
        newErrors.year1RateValue = checkFloat(state.year1RateValue);
      }
    }
    if (!state.year1RatesBenchmark && state.year1RateType === "floating") {
      newErrors.year1RatesBenchmark = "Year 1 Rates Benchmark is required.";
    }
    if (state.year1RateType === "hybrid") {
      if (!state.year1Hybrid1RateValue) {
        newErrors.year1Hybrid1RateValue =
          "Year 1 Hybrid 1 Rate Value is required.";
      } else if (state.year1Hybrid1RateValue) {
        if (checkFloat(state.year1Hybrid1RateValue) !== null) {
          newErrors.year1Hybrid1RateValue = checkFloat(
            state.year1Hybrid1RateValue
          );
        }
      }
      if (!state.year1Hybrid1RatePortion) {
        newErrors.year1Hybrid1RatePortion =
          "Year 1 Hybrid 1 Rate Portion is required.";
      } else if (state.year1Hybrid1RatePortion) {
        if (checkFloat(state.year1Hybrid1RatePortion) !== null) {
          newErrors.year1Hybrid1RatePortion = checkFloat(
            state.year1Hybrid1RatePortion
          );
        }
        if (state.year1Hybrid1RatePortion == 0) {
          newErrors.year1Hybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year1Hybrid1RatePortion) +
            parseFloat(state.year1Hybrid2RatePortion) >
          100
        ) {
          newErrors.year1Hybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year1Hybrid1RatePortion) +
            parseFloat(state.year1Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year1Hybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year1Hybrid2RateValue) {
        newErrors.year1Hybrid2RateValue =
          "Year 1 Hybrid 2 Rate Value is required.";
      } else if (state.year1Hybrid2RateValue) {
        if (checkFloat(state.year1Hybrid2RateValue) !== null) {
          newErrors.year1Hybrid2RateValue = checkFloat(
            state.year1Hybrid2RateValue
          );
        }
      }
      if (!state.year1Hybrid2RatePortion) {
        newErrors.year1Hybrid2RatePortion =
          "Year 1 Hybrid 2 Rate Portion is required.";
      } else if (state.year1Hybrid2RatePortion) {
        if (checkFloat(state.year1Hybrid2RatePortion) !== null) {
          newErrors.year1Hybrid2RatePortion = checkFloat(
            state.year1Hybrid2RatePortion
          );
        }
        if (state.year1Hybrid2RatePortion == 0) {
          newErrors.year1Hybrid2RatePortion =
            "Rate Portion for Hybrid 2 cannot be 0.";
        } else if (
          parseFloat(state.year1Hybrid1RatePortion) +
            parseFloat(state.year1Hybrid2RatePortion) >
          100
        ) {
          newErrors.year1Hybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year1Hybrid1RatePortion) +
            parseFloat(state.year1Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year1Hybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year1Hybrid2Benchmark) {
        newErrors.year1Hybrid2Benchmark =
          "Year 1 Hybrid 2 Benchmark is required.";
      }
    }
    if (!state.year2RateType) {
      newErrors.year2RateType = "Year 2 Rate Type is required.";
    }
    if (state.year2RateType !== "hybrid" && !state.year2RateValue) {
      newErrors.year2RateValue = "Year 2 Rate Value is required.";
    } else if (state.year2RateValue) {
      if (checkFloat(state.year2RateValue) !== null) {
        newErrors.year2RateValue = checkFloat(state.year2RateValue);
      }
    }
    if (!state.year2RatesBenchmark && state.year2RateType === "floating") {
      newErrors.year2RatesBenchmark = "Year 2 Rates Benchmark is required.";
    }
    if (state.year2RateType === "hybrid") {
      if (!state.year2Hybrid1RateValue) {
        newErrors.year2Hybrid1RateValue =
          "Year 2 Hybrid 1 Rate Value is required.";
      } else if (state.year2Hybrid1RateValue) {
        if (checkFloat(state.year2Hybrid1RateValue) !== null) {
          newErrors.year2Hybrid1RateValue = checkFloat(
            state.year2Hybrid1RateValue
          );
        }
      }
      if (!state.year2Hybrid1RatePortion) {
        newErrors.year2Hybrid1RatePortion =
          "Year 2 Hybrid 1 Rate Portion is required.";
      } else if (state.year2Hybrid1RatePortion) {
        if (checkFloat(state.year2Hybrid1RatePortion) !== null) {
          newErrors.year2Hybrid1RatePortion = checkFloat(
            state.year2Hybrid1RatePortion
          );
        }
        if (state.year2Hybrid1RatePortion == 0) {
          newErrors.year2Hybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year2Hybrid1RatePortion) +
            parseFloat(state.year2Hybrid2RatePortion) >
          100
        ) {
          newErrors.year2Hybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year2Hybrid1RatePortion) +
            parseFloat(state.year2Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year2Hybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year2Hybrid2RateValue) {
        newErrors.year2Hybrid2RateValue =
          "Year 2 Hybrid 2 Rate Value is required.";
      } else if (state.year2Hybrid2RateValue) {
        if (checkFloat(state.year2Hybrid2RateValue) !== null) {
          newErrors.year2Hybrid2RateValue = checkFloat(
            state.year2Hybrid2RateValue
          );
        }
      }
      if (!state.year2Hybrid2RatePortion) {
        newErrors.year2Hybrid2RatePortion =
          "Year 2 Hybrid 2 Rate Portion is required.";
      } else if (state.year2Hybrid2RatePortion) {
        if (checkFloat(state.year2Hybrid2RatePortion) !== null) {
          newErrors.year2Hybrid2RatePortion = checkFloat(
            state.year2Hybrid2RatePortion
          );
        }
        if (state.year2Hybrid2RatePortion == 0) {
          newErrors.year2Hybrid2RatePortion =
            "Rate Portion for Hybrid 2 cannot be 0.";
        } else if (
          parseFloat(state.year2Hybrid1RatePortion) +
            parseFloat(state.year2Hybrid2RatePortion) >
          100
        ) {
          newErrors.year2Hybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year2Hybrid1RatePortion) +
            parseFloat(state.year2Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year2Hybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year2Hybrid2Benchmark) {
        newErrors.year2Hybrid2Benchmark =
          "Year 2 Hybrid 2 Benchmark is required.";
      }
    }
    if (!state.year3RateType && state.year3RateValue) {
      newErrors.year3RateType = "Year 3 Rate Type is required.";
    }
    if (
      state.year3RateType !== "hybrid" &&
      !state.year3RateValue &&
      state.year3RateType !== null
    ) {
      newErrors.year3RateValue = "Year 3 Rate Value is required.";
    } else if (state.year3RateValue) {
      if (checkFloat(state.year3RateValue) !== null) {
        newErrors.year3RateValue = checkFloat(state.year3RateValue);
      }
    }
    if (!state.year3RatesBenchmark && state.year3RateType === "floating") {
      newErrors.year3RatesBenchmark = "Year 3 Rates Benchmark is required.";
    }
    if (state.year3RateType === "hybrid") {
      if (!state.year3Hybrid1RateValue) {
        newErrors.year3Hybrid1RateValue =
          "Year 3 Hybrid 1 Rate Value is required.";
      } else if (state.year3Hybrid1RateValue) {
        if (checkFloat(state.year3Hybrid1RateValue) !== null) {
          newErrors.year3Hybrid1RateValue = checkFloat(
            state.year3Hybrid1RateValue
          );
        }
      }
      if (!state.year3Hybrid1RatePortion) {
        newErrors.year3Hybrid1RatePortion =
          "Year 3 Hybrid 1 Rate Portion is required.";
      } else if (state.year3Hybrid1RatePortion) {
        if (checkFloat(state.year3Hybrid1RatePortion) !== null) {
          newErrors.year3Hybrid1RatePortion = checkFloat(
            state.year3Hybrid1RatePortion
          );
        }
        if (state.year3Hybrid1RatePortion == 0) {
          newErrors.year3Hybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year3Hybrid1RatePortion) +
            parseFloat(state.year3Hybrid2RatePortion) >
          100
        ) {
          newErrors.year3Hybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year3Hybrid1RatePortion) +
            parseFloat(state.year3Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year3Hybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year3Hybrid2RateValue) {
        newErrors.year3Hybrid2RateValue =
          "Year 3 Hybrid 2 Rate Value is required.";
      } else if (state.year3Hybrid2RateValue) {
        if (checkFloat(state.year3Hybrid2RateValue) !== null) {
          newErrors.year3Hybrid2RateValue = checkFloat(
            state.year3Hybrid2RateValue
          );
        }
      }
      if (!state.year3Hybrid2RatePortion) {
        newErrors.year3Hybrid2RatePortion =
          "Year 3 Hybrid 2 Rate Portion is required.";
      } else if (state.year3Hybrid2RatePortion) {
        if (checkFloat(state.year3Hybrid2RatePortion) !== null) {
          newErrors.year3Hybrid2RatePortion = checkFloat(
            state.year3Hybrid2RatePortion
          );
        }
        if (state.year3Hybrid2RatePortion == 0) {
          newErrors.year3Hybrid2RatePortion =
            "Rate Portion for Hybrid 2 cannot be 0.";
        } else if (
          parseFloat(state.year3Hybrid1RatePortion) +
            parseFloat(state.year3Hybrid2RatePortion) >
          100
        ) {
          newErrors.year3Hybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year3Hybrid1RatePortion) +
            parseFloat(state.year3Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year3Hybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year3Hybrid2Benchmark) {
        newErrors.year3Hybrid2Benchmark =
          "Year 3 Hybrid 2 Benchmark is required.";
      }
    }
    if (!state.year4RateType && state.year4RateValue) {
      newErrors.year4RateType = "Year 4 Rate Type is required.";
    }
    if (
      state.year4RateType !== "hybrid" &&
      !state.year4RateValue &&
      state.year4RateType !== null
    ) {
      newErrors.year4RateValue = "Year 4 Rate Value is required.";
    } else if (state.year4RateValue) {
      if (checkFloat(state.year4RateValue) !== null) {
        newErrors.year4RateValue = checkFloat(state.year4RateValue);
      }
    }
    if (!state.year4RatesBenchmark && state.year4RateType === "floating") {
      newErrors.year4RatesBenchmark = "Year 4 Rates Benchmark is required.";
    }
    if (state.year4RateType === "hybrid") {
      if (!state.year4Hybrid1RateValue) {
        newErrors.year4Hybrid1RateValue =
          "Year 4 Hybrid 1 Rate Value is required.";
      } else if (state.year4Hybrid1RateValue) {
        if (checkFloat(state.year4Hybrid1RateValue) !== null) {
          newErrors.year4Hybrid1RateValue = checkFloat(
            state.year4Hybrid1RateValue
          );
        }
      }
      if (!state.year4Hybrid1RatePortion) {
        newErrors.year4Hybrid1RatePortion =
          "Year 4 Hybrid 1 Rate Portion is required.";
      } else if (state.year4Hybrid1RatePortion) {
        if (checkFloat(state.year4Hybrid1RatePortion) !== null) {
          newErrors.year4Hybrid1RatePortion = checkFloat(
            state.year4Hybrid1RatePortion
          );
        }
        if (state.year4Hybrid1RatePortion == 0) {
          newErrors.year4Hybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year4Hybrid1RatePortion) +
            parseFloat(state.year4Hybrid2RatePortion) >
          100
        ) {
          newErrors.year4Hybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year4Hybrid1RatePortion) +
            parseFloat(state.year4Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year4Hybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year4Hybrid2RateValue) {
        newErrors.year4Hybrid2RateValue =
          "Year 4 Hybrid 2 Rate Value is required.";
      } else if (state.year4Hybrid2RateValue) {
        if (checkFloat(state.year4Hybrid2RateValue) !== null) {
          newErrors.year4Hybrid2RateValue = checkFloat(
            state.year4Hybrid2RateValue
          );
        }
      }
      if (!state.year4Hybrid2RatePortion) {
        newErrors.year4Hybrid2RatePortion =
          "Year 4 Hybrid 2 Rate Portion is required.";
      } else if (state.year4Hybrid2RatePortion) {
        if (checkFloat(state.year4Hybrid2RatePortion) !== null) {
          newErrors.year4Hybrid2RatePortion = checkFloat(
            state.year4Hybrid2RatePortion
          );
        }
        if (state.year4Hybrid2RatePortion == 0) {
          newErrors.year4Hybrid2RatePortion =
            "Rate Portion for Hybrid 2 cannot be 0.";
        } else if (
          parseFloat(state.year4Hybrid1RatePortion) +
            parseFloat(state.year4Hybrid2RatePortion) >
          100
        ) {
          newErrors.year4Hybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year4Hybrid1RatePortion) +
            parseFloat(state.year4Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year4Hybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year4Hybrid2Benchmark) {
        newErrors.year4Hybrid2Benchmark =
          "Year 4 Hybrid 2 Benchmark is required.";
      }
    }
    if (!state.year5RateType && state.year5RateValue) {
      newErrors.year5RateType = "Year 5 Rate Type is required.";
    }
    if (
      state.year5RateType !== "hybrid" &&
      !state.year5RateValue &&
      state.year5RateType !== null
    ) {
      newErrors.year5RateValue = "Year 5 Rate Value is required.";
    } else if (state.year5RateValue) {
      if (checkFloat(state.year5RateValue) !== null) {
        newErrors.year5RateValue = checkFloat(state.year5RateValue);
      }
    }
    if (!state.year5RatesBenchmark && state.year5RateType === "floating") {
      newErrors.year5RatesBenchmark = "Year 5 Rates Benchmark is required.";
    }
    if (state.year5RateType === "hybrid") {
      if (!state.year5Hybrid1RateValue) {
        newErrors.year5Hybrid1RateValue =
          "Year 5 Hybrid 1 Rate Value is required.";
      } else if (state.year5Hybrid1RateValue) {
        if (checkFloat(state.year5Hybrid1RateValue) !== null) {
          newErrors.year5Hybrid1RateValue = checkFloat(
            state.year5Hybrid1RateValue
          );
        }
      }
      if (!state.year5Hybrid1RatePortion) {
        newErrors.year5Hybrid1RatePortion =
          "Year 5 Hybrid 1 Rate Portion is required.";
      } else if (state.year5Hybrid1RatePortion) {
        if (checkFloat(state.year5Hybrid1RatePortion) !== null) {
          newErrors.year5Hybrid1RatePortion = checkFloat(
            state.year5Hybrid1RatePortion
          );
        }
        if (state.year5Hybrid1RatePortion == 0) {
          newErrors.year5Hybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year5Hybrid1RatePortion) +
            parseFloat(state.year5Hybrid2RatePortion) >
          100
        ) {
          newErrors.year5Hybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year5Hybrid1RatePortion) +
            parseFloat(state.year5Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year5Hybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year5Hybrid2RateValue) {
        newErrors.year5Hybrid2RateValue =
          "Year 5 Hybrid 2 Rate Value is required.";
      } else if (state.year5Hybrid2RateValue) {
        if (checkFloat(state.year5Hybrid2RateValue) !== null) {
          newErrors.year5Hybrid2RateValue = checkFloat(
            state.year5Hybrid2RateValue
          );
        }
      }
      if (!state.year5Hybrid2RatePortion) {
        newErrors.year5Hybrid2RatePortion =
          "Year 5 Hybrid 2 Rate Portion is required.";
      } else if (state.year5Hybrid2RatePortion) {
        if (checkFloat(state.year5Hybrid2RatePortion) !== null) {
          newErrors.year5Hybrid2RatePortion = checkFloat(
            state.year5Hybrid2RatePortion
          );
        }
        if (state.year5Hybrid2RatePortion == 0) {
          newErrors.year5Hybrid2RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.year5Hybrid1RatePortion) +
            parseFloat(state.year5Hybrid2RatePortion) >
          100
        ) {
          newErrors.year5Hybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.year5Hybrid1RatePortion) +
            parseFloat(state.year5Hybrid2RatePortion) !==
          100
        ) {
          newErrors.year5Hybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.year5Hybrid2Benchmark) {
        newErrors.year5Hybrid2Benchmark =
          "Year 5 Hybrid 2 Benchmark is required.";
      }
    }

    if (
      !state.boardRateValue &&
      (state.year1RatesBenchmark === "bbr" ||
        state.year2RatesBenchmark === "bbr" ||
        state.year3RatesBenchmark === "bbr" ||
        state.year4RatesBenchmark === "bbr" ||
        state.year5RatesBenchmark === "bbr" ||
        state.thereafterRatesBenchmark === "bbr")
    ) {
      newErrors.boardRateValue = "Board Rate Value is required.";
    } else if (state.boardRateValue) {
      if (checkFloat(state.boardRateValue) !== null) {
        newErrors.boardRateValue = checkFloat(state.boardRateValue);
      }
    }
    if (!state.thereafterRateType) {
      newErrors.thereafterRateType = "Thereafter Rate Type is required.";
    }
    if (state.thereafterRateType !== "hybrid" && !state.thereafterRateValue) {
      newErrors.thereafterRateValue = "Thereafter Rate Value is required.";
    } else if (state.thereafterRateValue) {
      if (checkFloat(state.thereafterRateValue) !== null) {
        newErrors.thereafterRateValue = checkFloat(state.thereafterRateValue);
      }
    }
    if (
      !state.thereafterRatesBenchmark &&
      state.thereafterRateType === "floating"
    ) {
      newErrors.thereafterRatesBenchmark =
        "Thereafter Rates Benchmark is required.";
    }
    if (state.thereafterRateType === "hybrid") {
      if (!state.thereafterHybrid1RateValue) {
        newErrors.thereafterHybrid1RateValue =
          "Thereafter Hybrid 1 Rate Value is required.";
      } else if (state.thereafterHybrid1RateValue) {
        if (checkFloat(state.thereafterHybrid1RateValue) !== null) {
          newErrors.thereafterHybrid1RateValue = checkFloat(
            state.thereafterHybrid1RateValue
          );
        }
      }
      if (!state.thereafterHybrid1RatePortion) {
        newErrors.thereafterHybrid1RatePortion =
          "Thereafter Hybrid 1 Rate Portion is required.";
      } else if (state.thereafterHybrid1RatePortion) {
        if (checkFloat(state.thereafterHybrid1RatePortion) !== null) {
          newErrors.thereafterHybrid1RatePortion = checkFloat(
            state.thereafterHybrid1RatePortion
          );
        }
        if (state.thereafterHybrid1RatePortion == 0) {
          newErrors.thereafterHybrid1RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.thereafterHybrid1RatePortion) +
            parseFloat(state.thereafterHybrid2RatePortion) >
          100
        ) {
          newErrors.thereafterHybrid1RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.thereafterHybrid1RatePortion) +
            parseFloat(state.thereafterHybrid2RatePortion) !==
          100
        ) {
          newErrors.thereafterHybrid1RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.thereafterHybrid2RateValue) {
        newErrors.thereafterHybrid2RateValue =
          "Thereafter Hybrid 2 Rate Value is required.";
      } else if (state.thereafterHybrid2RateValue) {
        if (checkFloat(state.thereafterHybrid2RateValue) !== null) {
          newErrors.thereafterHybrid2RateValue = checkFloat(
            state.thereafterHybrid2RateValue
          );
        }
      }
      if (!state.thereafterHybrid2RatePortion) {
        newErrors.thereafterHybrid2RatePortion =
          "Thereafter Hybrid 2 Rate Portion is required.";
      } else if (state.thereafterHybrid2RatePortion) {
        if (checkFloat(state.thereafterHybrid2RatePortion) !== null) {
          newErrors.thereafterHybrid2RatePortion = checkFloat(
            state.thereafterHybrid2RatePortion
          );
        }
        if (state.thereafterHybrid2RatePortion == 0) {
          newErrors.thereafterHybrid2RatePortion =
            "Rate Portion for Hybrid 1 cannot be 0.";
        } else if (
          parseFloat(state.thereafterHybrid1RatePortion) +
            parseFloat(state.thereafterHybrid2RatePortion) >
          100
        ) {
          newErrors.thereafterHybrid2RatePortion =
            "Total % for Hybrid 1 and Hybrid 2 cannot exceed 100.";
        } else if (
          parseFloat(state.thereafterHybrid1RatePortion) +
            parseFloat(state.thereafterHybrid2RatePortion) !==
          100
        ) {
          newErrors.thereafterHybrid2RatePortion =
            "Total % of loan for Hybrid 1 & 2 should be 100%";
        }
      }
      if (!state.thereafterHybrid2Benchmark) {
        newErrors.thereafterHybrid2Benchmark =
          "Thereafter Hybrid 2 Benchmark is required.";
      }
    }

    if (!state.lockInPeriod) {
      newErrors.lockInPeriod = "Lock-in Period is required.";
    } else if (state.lockInPeriod) {
      if (checkInt(state.lockInPeriod) !== null) {
        newErrors.lockInPeriod = checkInt(state.lockInPeriod);
      }
    }

    if (!state.legalSubsidyAmount && state.legalSubsidy) {
      newErrors.legalSubsidyAmount = "Legal Subsidy Amount is required.";
    } else if (state.legalSubsidyAmount) {
      if (checkFloat(state.legalSubsidyAmount, 2) !== null) {
        newErrors.legalSubsidyAmount = checkFloat(state.legalSubsidyAmount, 2);
      }
    }
    if (!state.legalSubsidyClawbackPeriod && state.legalSubsidy) {
      newErrors.legalSubsidyClawbackPeriod =
        "Legal Subsidy Clawback Period is required.";
    } else if (state.legalSubsidyClawbackPeriod) {
      if (checkInt(state.legalSubsidyClawbackPeriod) !== null) {
        newErrors.legalSubsidyClawbackPeriod = checkInt(
          state.legalSubsidyClawbackPeriod
        );
      }
    }

    if (
      state.freeValuationDescription &&
      state.freeValuationDescription.length > 350
    ) {
      newErrors.freeValuationDescription =
        "Free Valuation Description cannot exceed 350 characters.";
    } else if (
      state.freeValuationDescription &&
      cleanForm(state.freeValuationDescription)
    ) {
      newErrors.freeValuationDescription =
        'Text cannot contain "--", ";", "{", "}", "<", ">".';
    }
    if (
      state.partialPrepayment &&
      (state.partialPrepaymentYearsAllowed == "" ||
        state.partialPrepaymentYearsAllowed == null)
    ) {
      newErrors.partialPrepaymentYearsAllowed =
        "Partial Prepayment Years Allowed is required.";
    } else if (
      state.partialPrepayment &&
      (state.partialPrepaymentYearsAllowed !== "" ||
        state.partialPrepaymentYearsAllowed !== null)
    ) {
      if (checkFloat(state.partialPrepaymentYearsAllowed) !== null) {
        newErrors.partialPrepaymentYearsAllowed = checkFloat(
          state.partialPrepaymentYearsAllowed
        );
      }
    }
    if (
      state.partialPrepayment &&
      (state.partialPrepaymentPaymentAllowed == "" ||
        state.partialPrepaymentPaymentAllowed == null)
    ) {
      newErrors.partialPrepaymentPaymentAllowed =
        "Partial Prepayment Payment Allowed is required.";
    } else if (
      state.partialPrepayment &&
      (state.partialPrepaymentPaymentAllowed !== "" ||
        state.partialPrepaymentPaymentAllowed !== null)
    ) {
      if (checkFloat(state.partialPrepaymentPaymentAllowed) !== null) {
        newErrors.partialPrepaymentPaymentAllowed = checkFloat(
          state.partialPrepaymentPaymentAllowed
        );
      }
    }
    if (
      state.partialPrepayment &&
      (state.partialPrepaymentPenalty == "" ||
        state.partialPrepaymentPenalty == null)
    ) {
      newErrors.partialPrepaymentPenalty =
        "Partial Prepayment Penalty is required.";
    } else if (
      state.partialPrepayment &&
      (state.partialPrepaymentPenalty !== "" ||
        state.partialPrepaymentPenalty !== null)
    ) {
      if (checkFloat(state.partialPrepaymentPenalty) !== null) {
        newErrors.partialPrepaymentPenalty = checkFloat(
          state.partialPrepaymentPenalty
        );
      }
    }
    if (state.freeRepricing) {
      if (checkInt(state.monthsForRepricing) !== null) {
        newErrors.monthsForRepricing = checkInt(state.monthsForRepricing);
      }
    }
    if (
      state.freeValuationDescription &&
      state.freeValuationDescription.length > 350
    ) {
      newErrors.freeValuationDescription =
        "Free Valuation cannot exceed 350 characters.";
    } else if (
      state.freeValuationDescription &&
      cleanForm(state.freeValuationDescription)
    ) {
      newErrors.freeValuationDescription =
        'Text cannot contain "--", ";", "{", "}", "<", ">".';
    }
    if (state.remarks && state.remarks.length > 350) {
      newErrors.remarks = "Remarks cannot exceed 350 characters";
    } else if (state.remarks && cleanForm(state.remarks)) {
      newErrors.remarks = 'Text cannot contain "--", ";", "{", "}", "<", ">".';
    }
    if (state.features && state.features.length > 350) {
      newErrors.features = "Features cannot exceed 350 characters";
    } else if (state.features && cleanForm(state.features)) {
      newErrors.features = 'Text cannot contain "--", ";", "{", "}", "<", ">".';
    }
    if (state.minLoanQuantum) {
      if (state.minLoanQuantum && state.minLoanQuantum < 30000) {
        newErrors.minLoanQuantum =
          "Min Loan Quantum must be more than $30,000.";
      } else if (state.minLoanQuantum && state.minLoanQuantum > 999999999) {
        newErrors.minLoanQuantum = "Value should not be longer than 9 digits.";
      } else {
        if (checkFloat(state.minLoanQuantum) !== null) {
          newErrors.minLoanQuantum = checkFloat(state.minLoanQuantum);
        }
      }
    }
    if (!state.minLoanQuantum) {
      newErrors.minLoanQuantum = "Min Loan Quantum is required.";
    }
    if (
      state.buildingUnderConstruction === null ||
      state.buildingUnderConstruction === ""
    ) {
      newErrors.buildingUnderConstruction =
        "Building Under Construction is required.";
    }
    if (state.buildingUnderConstruction) {
      if (!state.cancellationFee) {
        newErrors.cancellationFee = "Cancellation Fee is required.";
      } else if (state.cancellationFee) {
        if (checkFloat(state.cancellationFee) !== null) {
          newErrors.cancellationFee = checkFloat(state.cancellationFee);
        }
      }
    }
    if (state.depositSavingsAccount) {
      if (checkFloat(state.depositSavingsAccount) !== null) {
        newErrors.depositSavingsAccount = checkFloat(
          state.depositSavingsAccount
        );
      }
    }
    if (state.depositCurrentAccount) {
      if (checkFloat(state.depositCurrentAccount) !== null) {
        newErrors.depositCurrentAccount = checkFloat(
          state.depositCurrentAccount
        );
      }
    }
    if (state.depositFixedDepositAccount) {
      if (checkFloat(state.depositFixedDepositAccount) !== null) {
        newErrors.depositFixedDepositAccount = checkFloat(
          state.depositFixedDepositAccount
        );
      }
    }
    if (state.lockinSell === null && state.lockinSell === "") {
      newErrors.lockinSell = "Lock-in Sell is required.";
    }
    if (state.monthsAllowedForSale) {
      if (checkInt(state.monthsAllowedForSale) !== null) {
        newErrors.monthsAllowedForSale = checkInt(state.monthsAllowedForSale);
      }
    }
    if (state.penaltyWaiver) {
      if (checkFloat(state.penaltyWaiver) !== null) {
        newErrors.penaltyWaiver = checkFloat(state.penaltyWaiver);
      }
    }

    setErrors(newErrors);
    console.log("errors", newErrors);
    return Object.keys(newErrors).length === 0; // return true if no errors (cannot use state to check)
  };

  const packageData = () => {
    return {
      package_name: state.selectedPackage,
      package_type: state.selectedPackageType,

      property_type: state.propertyType,
      property_location: state.propertyLocation,

      bank_name: checkuserRole(state.userRole) ? state.userRole : state.bank,

      is_active: state.checked,

      y1_rates: state.year1RateValue || null,
      y1_rates_cat: state.year1RateType,
      y1_rates_benchmark: state.year1RatesBenchmark,
      y1h1_rates: state.year1Hybrid1RateValue,
      y1h1_portion: state.year1Hybrid1RatePortion,
      y1h2_rates: state.year1Hybrid2RateValue,
      y1h2_portion: state.year1Hybrid2RatePortion,
      y1h2_benchmark: state.year1Hybrid2Benchmark,

      y2_rates: state.year2RateValue || null,
      y2_rates_cat: state.year2RateType,
      y2_rates_benchmark: state.year2RatesBenchmark,
      y2h1_rates: state.year2Hybrid1RateValue,
      y2h1_portion: state.year2Hybrid1RatePortion,
      y2h2_rates: state.year2Hybrid2RateValue,
      y2h2_portion: state.year2Hybrid2RatePortion,
      y2h2_benchmark: state.year2Hybrid2Benchmark,

      y3_rates: state.year3RateValue || null,
      y3_rates_cat: state.year3RateType,
      y3_rates_benchmark: state.year3RatesBenchmark,
      y3h1_rates: state.year3Hybrid1RateValue,
      y3h1_portion: state.year3Hybrid1RatePortion,
      y3h2_rates: state.year3Hybrid2RateValue,
      y3h2_portion: state.year3Hybrid2RatePortion,
      y3h2_benchmark: state.year3Hybrid2Benchmark,

      y4_rates: state.year4RateValue || null,
      y4_rates_cat: state.year4RateType,
      y4_rates_benchmark: state.year4RatesBenchmark,
      y4h1_rates: state.year4Hybrid1RateValue,
      y4h1_portion: state.year4Hybrid1RatePortion,
      y4h2_rates: state.year4Hybrid2RateValue,
      y4h2_portion: state.year4Hybrid2RatePortion,
      y4h2_benchmark: state.year4Hybrid2Benchmark,

      y5_rates: state.year5RateValue || null,
      y5_rates_cat: state.year5RateType,
      y5_rates_benchmark: state.year5RatesBenchmark,
      y5h1_rates: state.year5Hybrid1RateValue,
      y5h1_portion: state.year5Hybrid1RatePortion,
      y5h2_rates: state.year5Hybrid2RateValue,
      y5h2_portion: state.year5Hybrid2RatePortion,
      y5h2_benchmark: state.year5Hybrid2Benchmark,

      thereafter_rates: state.thereafterRateValue || null,
      thereafter_rates_cat: state.thereafterRateType,
      thereafter_rates_benchmark: state.thereafterRatesBenchmark,
      thereafterh1_rates: state.thereafterHybrid1RateValue,
      thereafterh1_portion: state.thereafterHybrid1RatePortion,
      thereafterh2_rates: state.thereafterHybrid2RateValue,
      thereafterh2_portion: state.thereafterHybrid2RatePortion,
      thereafterh2_benchmark: state.thereafterHybrid2Benchmark,

      board_rate: state.boardRateValue,
      partial_prepayment: state.partialPrepayment ? 1 : 0,

      partial_prepayment_years: state.partialPrepaymentYearsAllowed || null,

      partial_prepayment_amount: state.partialPrepaymentPaymentAllowed || null,

      partial_prepayment_penalty: state.partialPrepaymentPenalty || null,

      lockin_years: state.lockInPeriod,
      lockin_penalty: state.penaltyForBreaking,
      lockin_penalty_type: state.lockInPeriodDescription,

      legal_subsidy_cash_rebate: state.legalSubsidy ? 1 : 0,

      legal_subsidy_cash_rebate_amount: state.legalSubsidyAmount,

      legal_subsidy_cash_rebate_clawback_years:
        state.legalSubsidyClawbackPeriod,

      fire_insurance: state.fireInsurance ? 1 : 0,

      remarks: state.remarks,
      features: state.features,
      min_quantum: state.minLoanQuantum,
      buc: state.buildingUnderConstruction,

      buc_cancellation_penalty: state.cancellationFee,

      free_valuation: state.freeValuationDescription,
      free_repricing_conversion: state.freeRepricing ? 1 : 0,
      ...(state.freeRepricing && {
        free_repricing_conversion_months: state.monthsForRepricing,
      }),
      lockin_sell: state.lockinSell,

      credit_card: state.creditCardApplication ? 1 : 0,

      savings_account: state.openSavingsAccount ? 1 : 0,

      current_account: state.openCurrentAccount ? 1 : 0,

      fixed_deposit_account: state.openFixedDepositAccount ? 1 : 0,

      savings_account_amount: state.depositSavingsAccount || null,

      current_account_amount: state.depositCurrentAccount || null,

      fixed_deposit_account_amount: state.depositFixedDepositAccount || null,

      lockin_sell_after: state.monthsAllowedForSale || null,

      lockin_sell_waiver_pct: state.penaltyWaiver || null,
    };
  };

  const getPackageNames = async () => {
    try {
      const response = await axios.get(apiURL + "/api/getPackageNames", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      setPackageNames((prev) => [
        ...PackageNames,
        ...response.data.packageNames.map((item, i) => ({
          value: item.package_name_id,
          label: item.package_name,
        })),
      ]);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response?.data?.message
      );
    }
  };

  const getBenchmarks = async () => {
    try {
      const response = await axios.get(apiURL + "/api/getBenchmarks", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      setBenchmarks((prev) => [
        ...Benchmarks,
        ...response.data.map((item, i) => ({
          value: item.rate_type,
          label: item.rate_name,
        })),
      ]);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response?.data?.message
      );
    }
  };

  const addNewPackageName = async (obj) => {
    try {
      const response = await axios.post(apiURL + "/api/addPackageName", obj, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      getPackageNames();
      setState((state) => ({ ...state, selectedPackage: obj.package_name }));
      setErrors((prev) => ({ ...prev, packageName: "" }));
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
      setErrors((prev) => ({
        ...prev,
        packageName: error.response.data.message,
      }));
    }
  };

  const addNewPackage = async (newPackage = packageData()) => {
    const noErrors = validateFields();
    console.log(noErrors);
    if (noErrors) {
      try {
        const response = await axios.post(
          apiURL + "/api/addPackage",
          newPackage,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
              "x-app-id": "laksa",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Package added successfully:", response.data);
        setState((state) => ({ ...state, showModal: true }));
        setTimeout(() => {
          navigate(`/pages/loan-packages/all`);
        }, 777);
      } catch (error) {
        console.error(
          "There was an error sending the request:",
          error.response?.data?.message
        );
      }
    }
  };

  const addNewAndDuplicatePackage = async (newPackage = packageData()) => {
    const noErrors = validateFields();
    if (noErrors) {
      try {
        const response = await axios.post(
          apiURL + "/api/addAndDuplicatePackage",
          newPackage,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_API_KEY,
              "x-app-id": "laksa",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Packages added successfully:", response.data);
        setTimeout(() => {
          setState((state) => ({
            ...state,
            showModal: true,
            isModalRedirect: false,
          }));
        }, 333);
      } catch (error) {
        console.error(
          "There was an error sending the request:",
          error.response.data.message
        );
      }
    } else {
      console.log("Please make sure all fields are filled out correctly");
    }
  };

  const navigate = useNavigate();

  const cancel = () => {
    navigate(-1);
  };

  const updateRatesState = (
    stateName,
    event,
    setBenchmarkState,
    setBenchmarkStateText
  ) => {
    setState((state) => ({ ...state, [setBenchmarkState]: "" }));
    setState((state) => ({ ...state, [setBenchmarkStateText]: "" }));
    setState((state) => ({ ...state, [stateName]: event }));
  };

  const setBenchmark = (setBenchmark, setBenchmarkText, event) => {
    setState((state) => ({ ...state, [setBenchmark]: event.value }));
    setState((state) => ({ ...state, [setBenchmarkText]: event.label }));
  };

  const updateLockin = (stateName, event) => {
    setState((state) => ({ ...state, penaltyWaiver: null }));
    setState((state) => ({ ...state, tempPenaltyWaiver: null }));
    setState((state) => ({ ...state, monthsAllowedForSale: null }));
    setState((state) => ({ ...state, tempMonthsAllowedForSale: null }));
    setState((state) => ({ ...state, [stateName]: event }));
  };

  function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString.trim()) && dateString !== "") {
      return false;
    }

    // Parse the date parts to integers
    let parts = dateString.split("-");
    let year = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10);
    let day = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
      return false;
    }

    let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      newPackageName: "",
    }));
  }, [state.selectedPackage]); //reset newPackageName when selectedPackage changes

  useEffect(() => {
    getPackageNames();
    getBenchmarks();
  }, []);

  //reset fields when changes

  useEffect(() => {
    if (!state.legalSubsidy) {
      setState((state) => ({ ...state, legalSubsidyAmount: null }));
      setState((state) => ({ ...state, tempLegalSubsidyAmount: null }));
      setState((state) => ({ ...state, legalSubsidyClawbackPeriod: null }));
      setState((state) => ({ ...state, tempLegalSubsidyClawbackPeriod: null }));
    }
  }, [state.legalSubsidy]);

  useEffect(() => {
    if (!state.partialPrepayment) {
      setState((state) => ({ ...state, partialPrepaymentYearsAllowed: null }));
      setState((state) => ({
        ...state,
        partialPrepaymentPaymentAllowed: null,
      }));
      setState((state) => ({ ...state, partialPrepaymentPenalty: null }));
    }
  }, [state.partialPrepayment]);

  useEffect(() => {
    if (!state.freeRepricing) {
      setState((state) => ({ ...state, monthsForRepricing: null }));
    }
  }, [state.freeRepricing]);

  useEffect(() => {
    if (!state.openSavingsAccount) {
      setState((state) => ({ ...state, depositSavingsAccount: null }));
    }
  }, [state.openSavingsAccount]);

  useEffect(() => {
    if (!state.openCurrentAccount) {
      setState((state) => ({ ...state, depositCurrentAccount: null }));
    }
  }, [state.openCurrentAccount]);

  useEffect(() => {
    if (!state.openFixedDepositAccount) {
      setState((state) => ({ ...state, depositFixedDepositAccount: null }));
    }
  }, [state.openFixedDepositAccount]);

  useEffect(() => {
    if (!state.buildingUnderConstruction) {
      setState((state) => ({ ...state, cancellationFee: null }));
    }
  }, [state.buildingUnderConstruction]);

  useEffect(() => {
    console.log("benchmarks", benchmarks);
  }, [benchmarks]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolute />
      {state.showModal && (
        <NotificationModal
          setShowModal={
            state.showModal
              ? () => setState((state) => ({ ...state, showModal: false }))
              : null
          }
          message={"Add Loan Package Successfully"}
          isModalRedirect={state.isModalRedirect}
        />
      )}
      <SoftBox mt={5} mb={3}>
        <Card style={{ padding: "28px 40px" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <SoftButton
                    component={""}
                    onClick={() => cancel()}
                    variant="gradient"
                    color="error"
                  >
                    Cancel
                  </SoftButton>
                </Grid>
                <Grid item>
                  <SoftButton
                    component={""}
                    onClick={() => addNewPackage()}
                    variant="gradient"
                    color="success"
                  >
                    Add
                  </SoftButton>
                </Grid>
                <Grid item>
                  <SoftButton
                    component={""}
                    onClick={() => addNewAndDuplicatePackage()}
                    variant="gradient"
                    color="info"
                  >
                    Add & Duplicate
                  </SoftButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} xl={12}>
              <SoftBox mb={2}>
                <SoftTypography
                  variant="overline"
                  color="error"
                  style={{ marginBottom: "5px" }}
                >
                  Leave blank if any of the fields are not applicable. Fields
                  marked with * are mandatory.
                </SoftTypography>
                <SoftTypography variant="h6" style={{ marginBottom: "3px" }}>
                  Package Name*
                </SoftTypography>
                <SoftSelect
                  options={packageNames}
                  onChange={(event) =>
                    setState((state) => ({
                      ...state,
                      selectedPackage: event.value,
                    }))
                  }
                  size="small"
                  placeholder="Loan Package Name"
                />
                {state.selectedPackage === "new" && (
                  <Grid>
                    <SoftInput
                      style={{ marginTop: "16px" }}
                      placeholder="New Package Name"
                      value={state.newPackageName}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          newPackageName: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    <SoftButton
                      style={{ marginTop: "16px" }}
                      component={""}
                      onClick={() =>
                        addNewPackageName({
                          package_name: state.newPackageName,
                        })
                      }
                      variant="gradient"
                      color="primary"
                    >
                      Add new package
                    </SoftButton>
                  </Grid>
                )}

                {errors.packageName && (
                  <SoftTypography variant="body2" color="error">
                    {errors.packageName}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Package Type*
                </SoftTypography>
                <SoftSelect
                  options={PackageTypes}
                  onChange={(event) =>
                    setState((state) => ({
                      ...state,
                      selectedPackageType: event.value,
                    }))
                  }
                  size="small"
                  placeholder="Package Type"
                />
                {errors.packageType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.packageType}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Property Type*
                </SoftTypography>
                <SoftSelect
                  options={PropertyTypes}
                  onChange={(event) =>
                    setState((state) => ({
                      ...state,
                      propertyType: event.value,
                    }))
                  }
                  size="small"
                  placeholder="Package Type"
                />
                {errors.propertyType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.propertyType}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Property Location*
                </SoftTypography>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={1}
                    name="property-location"
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        propertyLocation: e.target.value,
                      }));
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={"Singapore"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label={"Malaysia"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label={"Australia"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      label={"Japan"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={5}
                      control={<Radio />}
                      label={"Thailand"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={6}
                      control={<Radio />}
                      label={"United Kingdom"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={7}
                      control={<Radio />}
                      label={"United States"}
                      sx={{ marginLeft: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Bank*
                </SoftTypography>
                {checkuserRole(userRole) ? (
                  <SoftInput
                    type="text"
                    placeholder="Bank"
                    size="small"
                    value={userRole}
                    disabled={true}
                  />
                ) : (
                  <SoftSelect
                    options={Banks}
                    onChange={(event) =>
                      setState((state) => ({ ...state, bank: event.value }))
                    }
                    size="small"
                    placeholder="Bank"
                  />
                )}
                {errors.bank && (
                  <SoftTypography variant="body2" color="error">
                    {errors.bank}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <SoftBox mb={2} ml={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.checked}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "controlled" }}
                        style={{ color: state.checked ? "green" : "" }}
                      />
                    }
                    label="Active*"
                  />
                </FormGroup>
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} xl={4}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Year 1 Rates Type*
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "year1RateType",
                      event.value,
                      "year1RatesBenchmark",
                      "year1RatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Year 1 Rates Type"
                />
                {errors.year1RateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.year1RateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.year1RateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates in %"
                      value={state.year1Hybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year1Hybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year1Hybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1Hybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates in %"
                      value={state.year1Hybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year1Hybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year1Hybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1Hybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates in %"
                      value={state.year1Hybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year1Hybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year1Hybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1Hybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.year1Hybrid2RateValue
                        ? state.year1Hybrid2RateValue + "% "
                        : "Year 1 Rates in % "}
                      +{" "}
                      {state.year1Hybrid2Benchmark
                        ? state.year1Hybrid2BenchmarkText
                        : "Year 1 Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "year1Hybrid2Benchmark",
                          "year1Hybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Year 1 Rates Benchmark Hybrid 2"
                    />
                    {errors.year1Hybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1Hybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates in %"
                      value={state.year1Hybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year1Hybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year1Hybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1Hybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.year1RateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 1 Rates in %*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 1 Rates in %"
                      value={state.tempYear1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempYear1RateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          year1RateValue: state.tempYear1RateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.year1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year1RateValue}
                      </SoftTypography>
                    )}
                    {state.year1RateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempYear1RateValue
                          ? state.tempYear1RateValue + "% "
                          : "Year 1 Rates in % "}
                        +{" "}
                        {state.year1RatesBenchmark
                          ? state.year1RatesBenchmarkText
                          : "Year 1 Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.year1RateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 1 Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "year1RatesBenchmark",
                            "year1RatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Year 1 Rates Benchmark"
                      />
                      {state.year1RateType === "floating" &&
                        errors.year1RatesBenchmark && (
                          <SoftTypography variant="body2" color="error">
                            {errors.year1RatesBenchmark}
                          </SoftTypography>
                        )}
                    </SoftBox>
                  )}
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Year 2 Rates Type*
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "year2RateType",
                      event.value,
                      "year2RatesBenchmark",
                      "year2RatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Year 2 Rates Type"
                />
                {errors.year2RateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.year2RateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.year2RateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates in %"
                      value={state.year2Hybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year2Hybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year2Hybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2Hybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates in %"
                      value={state.year2Hybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year2Hybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year2Hybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2Hybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates in %"
                      value={state.year2Hybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year2Hybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year2Hybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2Hybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.year2Hybrid2RateValue
                        ? state.year2Hybrid2RateValue + "% "
                        : "Year 2 Rates in % "}
                      +{" "}
                      {state.year2Hybrid2Benchmark
                        ? state.year2Hybrid2BenchmarkText
                        : "Year 2 Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "year2Hybrid2Benchmark",
                          "year2Hybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Year 2 Rates Benchmark Hybrid 2"
                    />
                    {errors.year2Hybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2Hybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates in %"
                      value={state.year2Hybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year2Hybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year2Hybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2Hybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.year2RateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 2 Rates in %*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 2 Rates in %"
                      value={state.tempYear2RateValue}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempYear2RateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          year2RateValue: state.tempYear2RateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.year2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year2RateValue}
                      </SoftTypography>
                    )}
                    {state.year2RateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempYear2RateValue
                          ? state.tempYear2RateValue + "% "
                          : "Year 2 Rates in % "}
                        +{" "}
                        {state.year2RatesBenchmark
                          ? state.year2RatesBenchmarkText
                          : "Year 2 Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.year2RateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 2 Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "year2RatesBenchmark",
                            "year2RatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Year 2 Rates Benchmark"
                      />
                      {errors.year2RatesBenchmark && (
                        <SoftTypography variant="body2" color="error">
                          {errors.year2RatesBenchmark}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  )}
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Year 3 Rates Type
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "year3RateType",
                      event.value,
                      "year3RatesBenchmark",
                      "year3RatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Year 3 Rates Type"
                />
                {errors.year3RateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.year3RateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.year3RateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates in %"
                      value={state.year3Hybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year3Hybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year3Hybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3Hybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates in %"
                      value={state.year3Hybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year3Hybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year3Hybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3Hybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates in %"
                      value={state.year3Hybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year3Hybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year3Hybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3Hybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.year3Hybrid2RateValue
                        ? state.year3Hybrid2RateValue + "% "
                        : "Year 3 Rates in % "}
                      +{" "}
                      {state.year3Hybrid2Benchmark
                        ? state.year3Hybrid2BenchmarkText
                        : "Year 3 Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "year3Hybrid2Benchmark",
                          "year3Hybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Year 3 Rates Benchmark Hybrid 2"
                    />
                    {errors.year3Hybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3Hybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates in %"
                      value={state.year3Hybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year3Hybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year3Hybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3Hybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.year3RateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 3 Rates in %
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 3 Rates in %"
                      value={state.tempYear3RateValue}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempYear3RateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          year3RateValue: state.tempYear3RateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.year3RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year3RateValue}
                      </SoftTypography>
                    )}
                    {state.year3RateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempYear3RateValue
                          ? state.tempYear3RateValue + "% "
                          : "Year 3 Rates in % "}
                        +{" "}
                        {state.year3RatesBenchmark
                          ? state.year3RatesBenchmarkText
                          : "Year 3 Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.year3RateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 3 Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "year3RatesBenchmark",
                            "year3RatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Year 3 Rates Benchmark"
                      />
                      {errors.year3RatesBenchmark && (
                        <SoftTypography variant="body2" color="error">
                          {errors.year3RatesBenchmark}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  )}
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Year 4 Rates Type
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "year4RateType",
                      event.value,
                      "year4RatesBenchmark",
                      "year4RatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Year 4 Rates Type"
                />
                {errors.year4RateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.year4RateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.year4RateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates in %"
                      value={state.year4Hybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year4Hybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year4Hybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4Hybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates in %"
                      value={state.year4Hybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year4Hybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year4Hybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4Hybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates in %"
                      value={state.year4Hybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year4Hybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year4Hybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4Hybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.year4Hybrid2RateValue
                        ? state.year4Hybrid2RateValue + "% "
                        : "Year 4 Rates in % "}
                      +{" "}
                      {state.year4Hybrid2Benchmark
                        ? state.year4Hybrid2BenchmarkText
                        : "Year 4 Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "year4Hybrid2Benchmark",
                          "year4Hybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Year 4 Rates Benchmark Hybrid 2"
                    />
                    {errors.year4Hybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4Hybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates in %"
                      value={state.year4Hybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year4Hybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year4Hybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4Hybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.year4RateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 4 Rates in %
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 4 Rates in %"
                      value={state.tempYear4RateValue}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempYear4RateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          year4RateValue: state.tempYear4RateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.year4RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year4RateValue}
                      </SoftTypography>
                    )}
                    {state.year4RateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempYear4RateValue
                          ? state.tempYear4RateValue + "% "
                          : "Year 4 Rates in % "}
                        +{" "}
                        {state.year4RatesBenchmark
                          ? state.year4RatesBenchmarkText
                          : "Year 4 Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.year4RateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 4 Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "year4RatesBenchmark",
                            "year4RatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Year 4 Rates Benchmark"
                      />
                      {errors.year4RatesBenchmark && (
                        <SoftTypography variant="body2" color="error">
                          {errors.year4RatesBenchmark}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  )}
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Year 5 Rates Type
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "year5RateType",
                      event.value,
                      "year5RatesBenchmark",
                      "year5RatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Year 5 Rates Type"
                />
                {errors.year5RateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.year5RateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.year5RateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates in %"
                      value={state.year5Hybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year5Hybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year5Hybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5Hybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates in %"
                      value={state.year5Hybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year5Hybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year5Hybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5Hybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates in %"
                      value={state.year5Hybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year5Hybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year5Hybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5Hybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.year5Hybrid2RateValue
                        ? state.year5Hybrid2RateValue + "% "
                        : "Year 5 Rates in % "}
                      +{" "}
                      {state.year5Hybrid2Benchmark
                        ? state.year5Hybrid2BenchmarkText
                        : "Year 5 Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "year5Hybrid2Benchmark",
                          "year5Hybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Year 5 Rates Benchmark Hybrid 2"
                    />
                    {errors.year5Hybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5Hybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates in %"
                      value={state.year5Hybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          year5Hybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.year5Hybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5Hybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.year5RateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Year 5 Rates in %
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Year 5 Rates in %"
                      value={state.tempYear5RateValue}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempYear5RateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          year5RateValue: state.tempYear5RateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.year5RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.year5RateValue}
                      </SoftTypography>
                    )}
                    {state.year5RateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempYear5RateValue
                          ? state.tempYear5RateValue + "% "
                          : "Year 5 Rates in % "}
                        +{" "}
                        {state.year5RatesBenchmark
                          ? state.year5RatesBenchmarkText
                          : "Year 5 Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.year5RateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Year 5 Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "year5RatesBenchmark",
                            "year5RatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Year 5 Rates Benchmark"
                        menuPlacement="top"
                      />
                      {errors.year5RatesBenchmark && (
                        <SoftTypography variant="body2" color="error">
                          {errors.year5RatesBenchmark}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  )}
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Thereafter Rates Type*
                </SoftTypography>
                <SoftSelect
                  options={RatesTypes}
                  onChange={(event) =>
                    updateRatesState(
                      "thereafterRateType",
                      event.value,
                      "thereafterRatesBenchmark",
                      "thereafterRatesBenchmarkText"
                    )
                  }
                  size="small"
                  placeholder="Thereafter Rates Type"
                />
                {errors.thereafterRateType && (
                  <SoftTypography variant="body2" color="error">
                    {errors.thereafterRateType}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.thereafterRateType === "hybrid" && (
                <SoftBox
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "6px",
                    borderRadius: "8px",
                  }}
                >
                  {/* **********Hybrid 1********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates Type Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates Type Hybrid 1"
                      value={"Fixed"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates in % Hybrid 1*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates in %"
                      value={state.thereafterHybrid1RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          thereafterHybrid1RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.thereafterHybrid1RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterHybrid1RateValue}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates Hybrid 1 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates in %"
                      value={state.thereafterHybrid1RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          thereafterHybrid1RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.thereafterHybrid1RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterHybrid1RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {/* **********Hybrid 2********** */}
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates Type Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates Type Hybrid 1"
                      value={"Floating"}
                      disabled={true}
                      size="small"
                    />
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates in % Hybrid 2*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates in %"
                      value={state.thereafterHybrid2RateValue || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          thereafterHybrid2RateValue: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.thereafterHybrid2RateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterHybrid2RateValue}
                      </SoftTypography>
                    )}

                    <SoftTypography variant="caption" color="success">
                      {state.thereafterHybrid2RateValue
                        ? state.thereafterHybrid2RateValue + "% "
                        : "Thereafter Rates in % "}
                      +{" "}
                      {state.thereafterHybrid2Benchmark
                        ? state.thereafterHybrid2BenchmarkText
                        : "Thereafter Rates Benchmark*"}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates Benchmark Hybrid 2*
                    </SoftTypography>
                    <SoftSelect
                      options={benchmarks}
                      onChange={(event) =>
                        setBenchmark(
                          "thereafterHybrid2Benchmark",
                          "thereafterHybrid2BenchmarkText",
                          event
                        )
                      }
                      size="small"
                      placeholder="Thereafter Rates Benchmark Hybrid 2"
                    />
                    {errors.thereafterHybrid2Benchmark && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterHybrid2Benchmark}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates Hybrid 2 for % of loan*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates in %"
                      value={state.thereafterHybrid2RatePortion || ""}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          thereafterHybrid2RatePortion: e.target.value,
                        }))
                      }
                      size="small"
                    />
                    {errors.thereafterHybrid2RatePortion && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterHybrid2RatePortion}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>
              )}
              {state.thereafterRateType !== "hybrid" && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Thereafter Rates in %*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      placeholder="Thereafter Rates in %"
                      value={state.tempThereafterRateValue}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempThereafterRateValue: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          thereafterRateValue: state.tempThereafterRateValue,
                        }))
                      }
                      size="small"
                    />
                    {errors.thereafterRateValue && (
                      <SoftTypography variant="body2" color="error">
                        {errors.thereafterRateValue}
                      </SoftTypography>
                    )}
                    {state.thereafterRateType === "floating" && (
                      <SoftTypography variant="caption" color="success">
                        {state.tempThereafterRateValue
                          ? state.tempThereafterRateValue + "% "
                          : "Thereafter Rates in % "}
                        +{" "}
                        {state.thereafterRatesBenchmark
                          ? state.thereafterRatesBenchmarkText
                          : "Thereafter Rates Benchmark*"}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  {state.thereafterRateType === "floating" && (
                    <SoftBox mb={2}>
                      <SoftTypography
                        variant="h6"
                        style={{ marginBottom: "5px" }}
                      >
                        Thereafter Rates Benchmark*
                      </SoftTypography>
                      <SoftSelect
                        options={benchmarks}
                        onChange={(event) =>
                          setBenchmark(
                            "thereafterRatesBenchmark",
                            "thereafterRatesBenchmarkText",
                            event
                          )
                        }
                        size="small"
                        placeholder="Thereafter Rates Benchmark"
                        menuPlacement="top"
                      />
                      {errors.thereafterRatesBenchmark && (
                        <SoftTypography variant="body2" color="error">
                          {errors.thereafterRatesBenchmark}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  )}
                </>
              )}
              {(state.year1RatesBenchmark === "bbr" ||
                state.year2RatesBenchmark === "bbr" ||
                state.year3RatesBenchmark === "bbr" ||
                state.year4RatesBenchmark === "bbr" ||
                state.year5RatesBenchmark === "bbr" ||
                state.thereafterRatesBenchmark === "bbr") && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Board Rate*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder="Board Rate"
                    value={state.boardRateValue}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        tempBoardRateValue: e.target.value,
                      }))
                    }
                    onBlur={() =>
                      setState((state) => ({
                        ...state,
                        boardRateValue: state.tempBoardRateValue,
                      }))
                    }
                    size="small"
                  />
                  {errors.boardRateValue && (
                    <SoftTypography variant="body2" color="error">
                      {errors.boardRateValue}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      legalSubsidy: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Legal Subsidy/Cash Rebate"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.legalSubsidy && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Legal Subsidy/Cash Rebate ($)*
                    </SoftTypography>
                    <SoftInput
                      placeholder="Legal Subsidy/Cash Rebate ($)"
                      value={state.tempLegalSubsidyAmount}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempLegalSubsidyAmount: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          legalSubsidyAmount: state.tempLegalSubsidyAmount,
                        }))
                      }
                      size="small"
                    />
                    {errors.legalSubsidyAmount && (
                      <SoftTypography variant="body2" color="error">
                        {errors.legalSubsidyAmount}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Legal Subsidy/Cash Rebate in clawback period (Years)*
                    </SoftTypography>
                    <SoftInput
                      placeholder="Legal Subsidy/Cash Rebate in clawback period (Years)"
                      value={state.tempLegalSubsidyClawbackPeriod}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          tempLegalSubsidyClawbackPeriod: e.target.value,
                        }))
                      }
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          legalSubsidyClawbackPeriod:
                            state.tempLegalSubsidyClawbackPeriod,
                        }))
                      }
                      size="small"
                    />
                    {errors.legalSubsidyClawbackPeriod && (
                      <SoftTypography variant="body2" color="error">
                        {errors.legalSubsidyClawbackPeriod}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </>
              )}

              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      fireInsurance: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Fire Insurance Description"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      freeRepricing: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Free Repricing/ Conversion"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.freeRepricing && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Numbers of months for Repricing*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={state.monthsForRepricing}
                    placeholder="Numbers of months for Repricing*"
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        monthsForRepricing: e.target.value,
                      }))
                    }
                    size="small"
                  />
                  {errors.monthsForRepricing && (
                    <SoftTypography variant="body2" color="error">
                      {errors.monthsForRepricing}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Free Valuation Description
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder="Free Valuation Description"
                  value={state.tempFreeValuationDescription}
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      tempFreeValuationDescription: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    setState((state) => ({
                      ...state,
                      freeValuationDescription:
                        state.tempFreeValuationDescription,
                    }))
                  }
                  size="small"
                  multiline
                  rows={3}
                />
                {errors.freeValuationDescription && (
                  <SoftTypography variant="body2" color="error">
                    {errors.freeValuationDescription}
                  </SoftTypography>
                )}
              </SoftBox>

              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      partialPrepayment: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Partial Prepayment"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.partialPrepayment && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Numbers of years allowed*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      value={state.partialPrepaymentYearsAllowed}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          partialPrepaymentYearsAllowed: e.target.value,
                        }))
                      }
                      placeholder="Numbers of years"
                      size="small"
                    />
                    {errors.partialPrepaymentYearsAllowed && (
                      <SoftTypography variant="body2" color="error">
                        {errors.partialPrepaymentYearsAllowed}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Payment allowed in %*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      value={state.partialPrepaymentPaymentAllowed}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          partialPrepaymentPaymentAllowed: e.target.value,
                        }))
                      }
                      placeholder="Partial Prepayment Payment"
                      size="small"
                    />
                    {errors.partialPrepaymentPaymentAllowed && (
                      <SoftTypography variant="body2" color="error">
                        {errors.partialPrepaymentPaymentAllowed}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Penalty in %*
                    </SoftTypography>
                    <SoftInput
                      type="text"
                      value={state.partialPrepaymentPenalty}
                      onChange={(e) =>
                        setState((state) => ({
                          ...state,
                          partialPrepaymentPenalty: e.target.value,
                        }))
                      }
                      placeholder="Partial Prepayment Penalty"
                      size="small"
                    />
                    {errors.partialPrepaymentPenalty && (
                      <SoftTypography variant="body2" color="error">
                        {errors.partialPrepaymentPenalty}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </>
              )}
            </Grid>
            <Grid item xs={12} md={4} xl={4}>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Min. Loan Quantum ($)*
                </SoftTypography>
                <SoftInput
                  placeholder="Min. Loan Quantum ($)"
                  value={state.tempMinLoanQuantum}
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      tempMinLoanQuantum: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    setState((state) => ({
                      ...state,
                      minLoanQuantum: state.tempMinLoanQuantum,
                    }))
                  }
                  size="small"
                />
                {errors.minLoanQuantum && (
                  <SoftTypography variant="body2" color="error">
                    {errors.minLoanQuantum}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Building Under Construction (BUC)*
                </SoftTypography>
                <SoftSelect
                  options={[
                    { value: null, label: "" },
                    { value: 1, label: "Yes" },
                    { value: 0, label: "No" },
                  ]}
                  onChange={(event) =>
                    setState((state) => ({
                      ...state,
                      buildingUnderConstruction: event.value,
                    }))
                  }
                  size="small"
                  placeholder="Building Under Construction (BUC)"
                />
                {errors.buildingUnderConstruction && (
                  <SoftTypography variant="body2" color="error">
                    {errors.buildingUnderConstruction}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.buildingUnderConstruction == 1 && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Cancellation Fee in %*
                  </SoftTypography>
                  <SoftInput
                    placeholder="Cancellation Fee"
                    value={state.cancellationFee}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        cancellationFee: e.target.value,
                      }))
                    }
                    size="small"
                  />
                  {errors.cancellationFee && (
                    <SoftTypography variant="body2" color="error">
                      {errors.cancellationFee}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}

              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      creditCardApplication: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Credit card application"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      openSavingsAccount: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Open savings account"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.openSavingsAccount && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Minimum deposit for savings account
                  </SoftTypography>
                  <SoftInput
                    placeholder="Minimum deposit for savings account"
                    value={state.depositSavingsAccount}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        depositSavingsAccount: e.target.value,
                      }))
                    }
                    size="small"
                  />
                  {errors.depositSavingsAccount && (
                    <SoftTypography variant="body2" color="error">
                      {errors.depositSavingsAccount}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      openCurrentAccount: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Open current account"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.openCurrentAccount && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Minimum deposit for current account
                  </SoftTypography>
                  <SoftInput
                    placeholder="Minimum deposit for current account"
                    value={state.depositCurrentAccount}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        depositCurrentAccount: e.target.value,
                      }))
                    }
                    size="small"
                  />
                  {errors.depositCurrentAccount && (
                    <SoftTypography variant="body2" color="error">
                      {errors.depositCurrentAccount}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <FormGroup
                  row
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      openFixedDepositAccount: e.target.checked,
                    }));
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Open fixed deposit account"
                    sx={{ marginLeft: "0px" }}
                  />
                </FormGroup>
              </SoftBox>
              {state.openFixedDepositAccount && (
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Minimum deposit for fixed deposit account
                  </SoftTypography>
                  <SoftInput
                    placeholder="Minimum deposit for fixed deposit account"
                    value={state.depositFixedDepositAccount}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        depositFixedDepositAccount: e.target.value,
                      }))
                    }
                    size="small"
                  />
                  {errors.depositFixedDepositAccount && (
                    <SoftTypography variant="body2" color="error">
                      {errors.depositFixedDepositAccount}
                    </SoftTypography>
                  )}
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Lock-in Period (No. of Years)*
                </SoftTypography>
                <SoftInput
                  placeholder="Lock-in Period (No. of Years)"
                  value={state.tempLockInPeriod}
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      tempLockInPeriod: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    setState((state) => ({
                      ...state,
                      lockInPeriod: state.tempLockInPeriod,
                    }))
                  }
                  size="small"
                />
                {errors.lockInPeriod && (
                  <SoftTypography variant="body2" color="error">
                    {errors.lockInPeriod}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Penalty for Breaking Lock-in (%)*
                </SoftTypography>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue="1.5"
                    name="lockin-penalty"
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        tempPenaltyForBreaking: e.target.value,
                      }));
                    }}
                    onBlur={() =>
                      setState((state) => ({
                        ...state,
                        penaltyForBreaking: state.tempPenaltyForBreaking,
                      }))
                    }
                  >
                    {[0, 0.75, 1, 1.25, 1.5, 1.75].map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value.toString()}
                        control={<Radio />}
                        label={value.toString()}
                        sx={{ marginLeft: 0 }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                {errors.penaltyForBreaking && (
                  <SoftTypography variant="body2" color="error">
                    {errors.penaltyForBreaking}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Lock-in Penalty Type*
                </SoftTypography>

                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={0}
                    name="lockin-penalty-type"
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        tempLockInPeriodDescription: e.target.value,
                      }));
                    }}
                    onBlur={() =>
                      setState((state) => ({
                        ...state,
                        lockInPeriodDescription:
                          state.tempLockInPeriodDescription,
                      }))
                    }
                  >
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={"Original Loan Amount"}
                      sx={{ marginLeft: 0 }}
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={"Outstanding Loan Amount"}
                      sx={{ marginLeft: 0 }}
                    />
                  </RadioGroup>
                </FormControl>
                {errors.lockInPeriodDescription && (
                  <SoftTypography variant="body2" color="error">
                    {errors.lockInPeriodDescription}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Waiver Due to Sales*
                </SoftTypography>
                <SoftSelect
                  options={[
                    { value: null, label: "" },
                    { value: 1, label: "Yes" },
                    { value: 0, label: "No" },
                  ]}
                  value={matchingOptionItem(
                    [
                      { value: null, label: "" },
                      { value: 1, label: "Yes" },
                      { value: 0, label: "No" },
                    ],
                    state.lockinSell
                  )}
                  onChange={(e) => updateLockin("lockinSell", e.value)}
                  size="small"
                  placeholder="Allow waiver due to sales?"
                />
                {errors.lockinSell && (
                  <SoftTypography variant="body2" color="error">
                    {errors.lockinSell}
                  </SoftTypography>
                )}
              </SoftBox>
              {state.lockinSell == 1 && (
                <>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      After how many months(If allowed)?
                    </SoftTypography>
                    <SoftInput
                      placeholder="After how many months(If allowed)?"
                      value={state.tempMonthsAllowedForSale}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          tempMonthsAllowedForSale: e.target.value,
                        }));
                      }}
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          monthsAllowedForSale: state.tempMonthsAllowedForSale,
                        }))
                      }
                      size="small"
                    />
                    {errors.monthsAllowedForSale && (
                      <SoftTypography variant="body2" color="error">
                        {errors.monthsAllowedForSale}
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Waiver of penalty (If allowed)(%)
                    </SoftTypography>
                    <SoftInput
                      placeholder="Waiver of penalty (If allowed)(%)"
                      value={state.tempPenaltyWaiver}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          tempPenaltyWaiver: e.target.value,
                        }));
                      }}
                      onBlur={() =>
                        setState((state) => ({
                          ...state,
                          penaltyWaiver: state.tempPenaltyWaiver,
                        }))
                      }
                      size="small"
                    />
                    {errors.penaltyWaiver && (
                      <SoftTypography variant="body2" color="error">
                        {errors.penaltyWaiver}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </>
              )}
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Remarks
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder="Remarks"
                  value={state.tempRemarks}
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      tempRemarks: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    setState((state) => ({
                      ...state,
                      remarks: state.tempRemarks,
                    }))
                  }
                  size="small"
                  multiline
                  rows={3}
                />
                {errors.remarks && (
                  <SoftTypography variant="body2" color="error">
                    {errors.remarks}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                  Features
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder="Features"
                  value={state.tempFeatures}
                  onChange={(e) =>
                    setState((state) => ({
                      ...state,
                      tempFeatures: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    setState((state) => ({
                      ...state,
                      features: state.tempFeatures,
                    }))
                  }
                  size="small"
                  multiline
                  rows={3}
                />
                {errors.features && (
                  <SoftTypography variant="body2" color="error">
                    {errors.features}
                  </SoftTypography>
                )}
              </SoftBox>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} xl={8}>
              &nbsp;
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => cancel()}
                      variant="gradient"
                      color="error"
                    >
                      Cancel
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => addNewPackage()}
                      variant="gradient"
                      color="success"
                    >
                      Add
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => addNewAndDuplicatePackage()}
                      variant="gradient"
                      color="info"
                    >
                      Add & Duplicate
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default AddPackage;
