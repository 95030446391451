const PackageNames = [
  { value: null, label: "" },
  { value: "new", label: "Add new package" },
  // { value: "1yf", label: "Best 1 Year Fixed Rate Package" },
  // { value: "2yf", label: "Best 2 Years Fixed Rate Package" },
  // { value: "3yf", label: "Best 3 Years Fixed Rate Package" },
  // { value: "4yf", label: "Best 4 Years Fixed Rate Package" },
  // { value: "5yf", label: "Best 5 Years Fixed Rate Package" },
  // { value: "1msoraio", label: "Best 1 Month SORA Interest Offset Package" },
  // { value: "3msoraio", label: "Best 3 Months SORA Interest Offset Package" },
  // { value: "1yl3msora", label: "Best 1 Year Lock-in 3 Months SORA Package" },
  // { value: "2yl3msora", label: "Best 2 Years Lock-in 3 Months SORA Package" },
  // { value: "1msoranl", label: "Best No Lock-in 1m SORA Package" },
  // { value: "3msoranl", label: "Best No Lock-in 3m SORA Package" },
  // {
  //   value: "1msoraionl",
  //   label: "Best No Lock-in 1m SORA Interest Offset Package",
  // },
  // {
  //   value: "3msoraionl",
  //   label: "Best No Lock-in 3m SORA Interest Offset Package",
  // },
  // { value: "fhr6nl", label: "Best No Lock-in FHR6 Package" },
  // { value: "fd", label: "Best Fixed Deposit Rate Package" },
  // { value: "3ylfloat", label: "Best 3 Years Lock-in Floating Package" },
  // { value: "3ylhybrid", label: "Best 3 Years Lock-in Hybrid Package" },
];

export default PackageNames;
