// App.js
import { useState, useEffect } from "react";

//Authentication
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/Loading";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ProtectedRouteWrapper from "./components/ProtectedRouteWrapper";
import Basic from "layouts/authentication/sign-in/basic";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import { apiURL } from "commons/apiURL";

// import axios
import axios from "axios";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard PRO React themes
import theme from "assets/theme";
// import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
// import rtlPlugin from "stylis-plugin-rtl";
// import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// Soft UI Dashboard PRO React routes
import routes from "routes";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/logo-ct.svg";

// Error pages
import Error404 from "layouts/authentication/error/404";
import Error403 from "layouts/authentication/error/403";

// Pages
import Leads from "layouts/pages/leads";
import ViewLead from "layouts/pages/leads/view";
import EditLead from "layouts/pages/leads/edit";
import NewLead from "layouts/pages/leads/new";
import Packages from "layouts/pages/loan-packages/all";
import ManagePackageNames from "layouts/pages/loan-packages/manage";
import AddPackageName from "layouts/pages/loan-packages/manage/add";
import EditPackageName from "layouts/pages/loan-packages/manage/edit";
import AddPackage from "layouts/pages/loan-packages/add";
import ViewPackage from "layouts/pages/loan-packages/view";
import EditPackage from "layouts/pages/loan-packages/edit";
import ReportsBankers from "layouts/pages/users/reports/bankers";
import ReportsConsultants from "layouts/pages/users/reports/consultants";
import ReportsSuperadmins from "layouts/pages/users/reports/superadmins";
import NetworkStatus from "commons/NetworkStatus";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isBank, setIsBank] = useState(false);
  // const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  // useMemo(() => {
  //   const cacheRtl = createCache({
  //     key: "rtl",
  //     stylisPlugins: [rtlPlugin],
  //   });
  //   setRtlCache(cacheRtl);
  // }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    if (window.location.pathname.includes("/banks/")) {
      setIsBank(true);
    }
  }, [isBank]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  // const configsButton = (
  //   <SoftBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.5rem"
  //     height="3.5rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="default" color="inherit">
  //       settings
  //     </Icon>
  //   </SoftBox>
  // );

  const { user, isAuthenticated, isLoading, error } = useAuth0();
  const navigate = useNavigate();
  const [packageId, setPackageId] = useState(null);
  const [consultants, setConsultants] = useState([]);

  const userRole = () => {
    try {
      const userRole =
        user["https://sales.loansupermart.sg/roles"][0].toUpperCase();
    } catch (error) {
      if (
        window.location.pathname !== "/authentication/sign-in/basic" &&
        window.location.pathname !== "/" &&
        !isLoading
      ) {
        navigate("error/403");
      }
    }
  };

  useEffect(() => {
    userRole();
  }, [isLoading, user, navigate]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated)
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Basic />} />
        </Routes>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NetworkStatus />
      {layout === "dashboard" && !isBank && isAuthenticated && !isLoading && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="CRM"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}

      {layout === "vr" && <Configurator />}
      <Routes>
        <Route
          path="/authentication/sign-in/basic"
          element={
            !isAuthenticated ? (
              <Basic />
            ) : (
              <Navigate to="/dashboards/crm" replace />
            )
          }
        />
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Basic />
            ) : (
              <Navigate to="/dashboards/crm" replace />
            )
          }
        />
        <Route
          path="/dashboards/crm"
          element={
            <ProtectedRouteWrapper>
              <CRM />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/pages/leads/:param"
          element={
            <ProtectedRouteWrapper>
              <Leads user={user} />
            </ProtectedRouteWrapper>
          }
        />
        {consultants && consultants !== undefined && (
          <Route
            path={`/pages/leads/view/:leadId`}
            element={
              <ProtectedRouteWrapper>
                <ViewLead user={user} consultants={consultants} />
              </ProtectedRouteWrapper>
            }
          />
        )}
        {consultants && consultants !== undefined && (
          <Route
            path={`/pages/leads/edit/:leadId`}
            element={
              <ProtectedRouteWrapper>
                <EditLead user={user} consultants={consultants} />
              </ProtectedRouteWrapper>
            }
          />
        )}
        <Route
          path={`/pages/leads/new`}
          element={
            <ProtectedRouteWrapper>
              <NewLead user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route path="/pages/loan-packages/manage-package-names">
          <Route
            path=""
            element={
              <ProtectedRouteWrapper>
                <ManagePackageNames user={user} />
              </ProtectedRouteWrapper>
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRouteWrapper>
                <AddPackageName user={user} />
              </ProtectedRouteWrapper>
            }
          />
          <Route
            path="edit/:packageNameId"
            element={
              <ProtectedRouteWrapper>
                <EditPackageName user={user} />
              </ProtectedRouteWrapper>
            }
          />
        </Route>
        <Route
          path="/pages/loan-packages/all"
          element={
            <ProtectedRouteWrapper>
              <Packages
                user={user}
                packageId={packageId}
                setPackageId={setPackageId}
              />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/pages/loan-packages/add"
          element={
            <ProtectedRouteWrapper>
              <AddPackage user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path={`/pages/loan-packages/view/:packageId`}
          element={
            <ProtectedRouteWrapper>
              <ViewPackage user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path={`/pages/loan-packages/edit/:packageId`}
          element={
            <ProtectedRouteWrapper>
              <EditPackage user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/users/reports/bankers"
          element={
            <ProtectedRouteWrapper>
              <ReportsBankers user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/users/reports/consultants"
          element={
            <ProtectedRouteWrapper>
              <ReportsConsultants user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/users/reports/superadmins"
          element={
            <ProtectedRouteWrapper>
              <ReportsSuperadmins user={user} />
            </ProtectedRouteWrapper>
          }
        />
        <Route
          path="/pages/profile/profile-overview"
          element={
            <ProtectedRouteWrapper>
              <ProfileOverview />
            </ProtectedRouteWrapper>
          }
        />
        <Route path="/error/403" element={<Error403 />} />
        <Route path="/error/404" element={<Error404 />} />
        <Route
          path="*"
          element={
            isAuthenticated ? (
              <Navigate to="/error/404" replace />
            ) : (
              <Navigate to="/authentication/sign-in/basic" replace />
            )
          }
        />
      </Routes>
    </ThemeProvider>
  );
}
