// src/layouts/pages/loan-packages/add/index.js
import { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";
import { GetCurrentTime } from "commons/DateTime";
import { sqlInjectionKeywords } from "commons/FormCleansing";

// @mui material components
import { Grid, Card } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftDropzone from "components/SoftDropzone";
import PropertyTypes from "data/PropertyTypes";
import Nationalities from "data/Nationalities";
import ResidentialStatuses from "data/ResidentialStatuses";
import HousingTypes from "data/HousingTypes";

import axios from "axios";
import NotificationModal from "components/NotificationModal";

import { apiURL } from "commons/apiURL";
import LeadStatuses from "data/LeadStatuses";
import * as api from "api";

const dropzoneOptions = {
  addRemoveLinks: true,
  acceptedFiles:
    "image/*,application/pdf,.doc,.docx,.pdf,.xls,.xlsx,application/msword,\
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,\
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/zip,application/x-7z-compressed",
  autoProcessQueue: false,
  maxFiles: 5,
};

function NewLead(props) {
  const user = props.user;
  const navigate = useNavigate();
  const dropzoneRef = useRef(null);
  const setDropzoneRef = (ref) => {
    dropzoneRef.current = ref;
  };

  // States
  const [showModal, setShowModal] = useState(false);
  const [packageNames, setPackageNames] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [errors, setErrors] = useState({});
  const [thisLead, setThisLead] = useState({});
  const [consultants, setConsultants] = useState([]);
  const [bankers, setBankers] = useState([]);
  const [activePackage, setActivePackage] = useState({});
  const [name, setName] = useState("");
  const [tempName, setTempName] = useState("");
  const [gender, setGender] = useState(null);
  const [mobile, setMobile] = useState("");
  const [tempMobile, setTempMobile] = useState("");
  const [email, setEmail] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [dob, setDob] = useState("");
  const [tempDob, setTempDob] = useState("");
  const [nationalities, setNationalities] = useState(null);
  const [residentialStatus, setResidentialStatus] = useState(null);
  const [housingType, setHousingType] = useState(null);
  const [loanPropertyLocation, setLoanPropertyLocation] = useState(null);
  const [newPropertyType, setNewPropertyType] = useState(null);
  const [buc, setBuc] = useState(null);
  const [financingType, setFinancingType] = useState(null);
  const [loanAmount, setLoanAmount] = useState("");
  const [tempLoanAmount, setTempLoanAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [tempTenure, setTempTenure] = useState("");
  const [newPropertyName, setNewPropertyName] = useState("");
  const [tempNewPropertyName, setTempNewPropertyName] = useState("");
  const [loanPackage, setLoanPackage] = useState(null);
  const [loanPackages, setLoanPackages] = useState(null);
  const [files, setFiles] = useState([]);
  const [leadStatus, setLeadStatus] = useState(null);
  const [assignedTo, setAssignedTo] = useState(null);
  const [assignedBanker, setAssignedBanker] = useState(null);
  const [reminder, setReminder] = useState(null);

  const getPackageById = async (packageId) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/getPackage/${packageId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        }
      );
      setActivePackage(response.data[0]);
      console.log("Package found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };
  const Gender = [
    { value: "FEMALE", label: "Female" },
    { value: "MALE", label: "Male" },
    { value: "UNKNOWN", label: "Unknown" },
  ];

  const options = useMemo(() => dropzoneOptions, []);

  const getConsultants = useCallback(async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getConsultants`);
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setConsultants(cns);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  }, []);

  const getBankers = useCallback(async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getBankers`);
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setBankers(cns);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  }, []);

  const getLoanPackages = async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getPackages`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "laksa",
          "Content-Type": "application/json",
        },
      });
      console.log("Packages found successfully:", response.data);
      let packageList = [];
      let packageName = "";
      response.data.map((p) => {
        packageNames.map((P) => {
          if (p.package_name === P.value) {
            packageName = P.label;
          }
        });
        packageList.push({
          value: p.package_id,
          label:
            packageName +
            " - " +
            p.bank_name +
            " - above $" +
            parseInt(p.min_quantum).toLocaleString("en-US"),
        });
      });
      setLoanPackages(packageList);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const matchingLabel = (obj, value) => {
    return obj.find((option) => option.value === value).label;
  };

  const matchingOption = (obj, value) => {
    return obj.find((option) => option.value === value);
  };

  const getRateWithBenchmark = (rate, benchmarkValue, rateCategory) => {
    const benchmarkLabel = benchmarks.find(
      (b) => b.value === benchmarkValue
    )?.label;
    if (rate) {
      if (rateCategory.toUpperCase() === "FIXED") {
        return `${rate}% ${benchmarkLabel || rateCategory.toUpperCase()}`;
      }
      return `${rate}% + ${benchmarkLabel || rateCategory.toUpperCase()}`;
    }
    return null;
  };

  const ViewPackage = (thisPackageId) => {
    navigate(`/pages/loan-packages/view/${thisPackageId}`);
  };

  const cleanForm = (value) => {
    return sqlInjectionKeywords.some((keyword) =>
      value.toUpperCase().includes(keyword.toUpperCase())
    );
  };

  const checkFloat = (value) => {
    const parts = String(value).split(".");
    if (parts.length === 2 && parts[1].length > 3) {
      return "Value must not have more than 3 decimal places.";
    } else if (!/^\d+(\.\d{1,3})?$/.test(value)) {
      return "Value must be a valid number with up to 3 decimal places.";
    } else if (value.length > 30) {
      return "Value cannot exceed 30 characters.";
    } else {
      return null;
    }
  };

  const checkInt = (value) => {
    if (!/^\d+$/.test(value)) {
      return "Value must be a valid number. Decimals are not allowed.";
    } else if (value.length > 20) {
      return "Value cannot exceed 20 characters.";
    } else {
      return null;
    }
  };

  const validDateLessThanToday = (value) => {
    const providedDate = new Date(value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (providedDate < today) {
      return false;
    }
    return true;
  };

  function isValidDate(dateString) {
    // First check for the pattern
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  const validDate = (value) => {
    if (isValidDate(value)) {
      return "Please key in date in dd/mm/yyyy format";
    } else if (validDateLessThanToday(value)) {
      return "Date of birth cannot be after today";
    } else {
      return null;
    }
  };

  const validateMobile = (mobileNo) => {
    // ^[89]\d{7}$ - Starts with an '8' or '9' followed by exactly 7 digits
    const regex = /^[89]\d{7}$/;
    return regex.test(mobileNo);
  };

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return regex.test(email);
  }

  const validateFields = () => {
    let newErrors = {};

    if (!name) {
      newErrors.name = "Name of Lead is required.";
    }
    if (!mobile) {
      newErrors.mobile = "Mobile No. of Lead is required.";
    } else if (mobile && !validateMobile(mobile)) {
      newErrors.mobile =
        "Enter a 8 digit Singapore mobile number starting with 8 or 9 only";
    }
    if (!email) {
      newErrors.email = "Email of Lead is required.";
    } else if (email && !validateEmail(email)) {
      newErrors.email = "Enter a valid email";
    }
    if (dob && cleanForm(dob)) {
      newErrors.dob = 'Text cannot contain "--", ";", "{", "}", "<", ">".';
    } else if (validDate(dob) !== null) {
      newErrors.dob = validDate(dob);
    }
    if (!gender) {
      newErrors.gender = "Gender of Lead is required.";
    }
    if (!nationalities) {
      newErrors.nationalities = "Nationality of Lead is required.";
    }
    if (!residentialStatus) {
      newErrors.residentialStatus = "Residential Status of Lead is required.";
    }
    if (!housingType) {
      newErrors.housingType = "Current Housing Type of Lead is required.";
    }
    if (!loanPropertyLocation) {
      newErrors.loanPropertyLocation =
        "Location of Property for loan application is required.";
    }
    if (!newPropertyType) {
      newErrors.newPropertyType = "Type of New Property is required.";
    }
    if (!buc) {
      newErrors.buc = "Status of Building Under Construction is required.";
    }
    if (!financingType) {
      newErrors.financingType = "Type of Financing is required.";
    }
    if (!loanAmount) {
      newErrors.loanAmount = "Loan Amount is required.";
    } else if (loanAmount && cleanForm(loanAmount)) {
      newErrors.loanAmount =
        'Text cannot contain "--", ";", "{", "}", "<", ">".';
    } else if (checkFloat(loanAmount) !== null) {
      newErrors.loanAmount = checkFloat(loanAmount);
    }
    if (!tenure) {
      newErrors.tenure = "Loan Tenure is required.";
    } else if (tenure) {
      if (checkInt(tenure) !== null) {
        newErrors.tenure = checkInt(tenure);
      }
    }
    if (!leadStatus) {
      newErrors.leadStatus = "Lead Status is required.";
    }
    if (!assignedTo) {
      newErrors.assignedTo = "Assigned to Consultant is required.";
    }
    if (!loanPackage) {
      newErrors.loanPackages = "Loan package is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const validateLostFields = () => {
    let newErrors = {};

    if (!name) {
      newErrors.name = "Name of Lead is required.";
    }
    if (!mobile) {
      newErrors.mobile = "Mobile No. of Lead is required.";
    } else if (mobile && !validateMobile(mobile)) {
      newErrors.mobile =
        "Enter a 8 digit Singapore mobile number starting with 8 or 9 only";
    }
    if (!email) {
      newErrors.email = "Email of Lead is required.";
    } else if (email && !validateEmail(email)) {
      newErrors.email = "Enter a valid email";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const add = async () => {
    const noErrors =
      leadStatus?.value !== "L" ? validateFields() : validateLostFields();
    let qfiles = [];
    let newLead = {};
    if (noErrors) {
      if (
        dropzoneRef.current &&
        typeof dropzoneRef.current.processQueue === "function"
      ) {
        qfiles = dropzoneRef.current.getQueuedFiles();
        console.log(qfiles);
        dropzoneRef.current.processQueue();
      } else {
        console.error(
          "Dropzone not initialized yet or processQueue is not available."
        );
      }
      if (qfiles) {
        newLead = {
          name: name,
          gender: gender?.value || "",
          mobile: mobile,
          email: email,
          dob: dob || "",
          nationality: nationalities?.value || "",
          residential_status: residentialStatus?.value || "",
          current_housing: housingType?.value || "",
          loan_property_location: loanPropertyLocation?.value || "",
          new_property: newPropertyType?.value || "",
          buc: buc?.value || "",
          financing_type: financingType?.value || "",
          loan_amount: loanAmount || "",
          tenure_required: tenure || "",
          new_property_name: newPropertyName || "",
          loan_package: loanPackage?.value || "",
          docs_uploaded: qfiles,
          status: leadStatus?.value,
          assigned_to: assignedTo || "",
          assigned_banker: assignedBanker || "",
          verified_data: false,
          datetime_created: GetCurrentTime(),
          datetime_updated: GetCurrentTime(),
          reminder: reminder,
        };
      }
      try {
        const response = await axios.post(`${apiURL}/api/addLead`, newLead, {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        });

        console.log("Lead added successfully:", response.data);
        setTimeout(() => {
          navigate(`/pages/leads/view/${response.data.body?.lead_id}`);
        }, 1200);
      } catch (error) {
        console.error(
          "There was an error sending the request:",
          error.response ? error.response.data.message : error.message
        );
      }
    }
  };

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    getConsultants();
    getBankers();
    getLoanPackages();
    getPackageNames();
    getBenchmarks();
  }, []);

  const getPackageNames = async (page) => {
    try {
      const data = await api.getPackageNames();
      console.log("API Response:", data);
      if (data) {
        setPackageNames(
          data.packageNames.map((item) => ({
            id: item.package_name_id,
            name: item.package_name,
          }))
        );
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getBenchmarks = async (page) => {
    try {
      const data = await api.getBenchmarks();
      console.log("API Response:", data);
      if (data) {
        setBenchmarks((prev) => [
          ...data.map((item, i) => ({
            value: item.rate_type,
            label: item.rate_name,
          })),
        ]);
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  useEffect(() => {
    console.log("activePackage is", activePackage);
  }, [activePackage]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolutedisabled={true} />
      {showModal && (
        <NotificationModal
          setShowModal={setShowModal}
          message={"Updated Successfully"}
          disabled={true}
        />
      )}
      {thisLead && consultants ? (
        <SoftBox mt={5} mb={3}>
          <Card style={{ padding: "28px 40px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Name*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.name ? thisLead.name : tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    onBlur={() => setName(tempName)}
                    size="small"
                    disabled={thisLead.verified_data}
                  />
                  {errors.name && (
                    <SoftTypography variant="body2" color="error">
                      {errors.name}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Mobile Number*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.mobile ? thisLead.mobile : tempMobile}
                    onChange={(e) => setTempMobile(e.target.value)}
                    onBlur={() => setMobile(tempMobile)}
                    size="small"
                    disabled={thisLead.verified_data}
                  />
                  {errors.mobile && (
                    <SoftTypography variant="body2" color="error">
                      {errors.mobile}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Email*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.email ? thisLead.email : tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                    onBlur={() => setEmail(tempEmail)}
                    size="small"
                    disabled={thisLead.verified_data}
                  />
                  {errors.email && (
                    <SoftTypography variant="body2" color="error">
                      {errors.email}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Gender*
                  </SoftTypography>
                  {thisLead.gender && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(Gender, thisLead.gender)}
                      size="small"
                      disabled={thisLead.verified_data}
                    />
                  ) : (
                    <SoftSelect
                      options={Gender}
                      onChange={(event) => setGender(event)}
                      value={thisLead.gender ? thisLead.gender : gender}
                      size="small"
                    />
                  )}
                  {errors.gender && (
                    <SoftTypography variant="body2" color="error">
                      {errors.gender}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Nationality*
                  </SoftTypography>
                  {thisLead.nationality && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(Nationalities, thisLead.nationality)}
                      size="small"
                      disabled={thisLead.verified_data}
                    />
                  ) : (
                    <SoftSelect
                      options={Nationalities}
                      onChange={(event) => setNationalities(event)}
                      value={
                        thisLead.nationality
                          ? thisLead.nationality
                          : nationalities
                      }
                      size="small"
                    />
                  )}
                  {errors.nationalities && (
                    <SoftTypography variant="body2" color="error">
                      {errors.nationalities}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Residential Status*
                  </SoftTypography>
                  {thisLead.residential_status && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(
                        ResidentialStatuses,
                        thisLead.residential_status
                      )}
                      size="small"
                      disabled={thisLead.verified_data}
                    />
                  ) : (
                    <SoftSelect
                      options={ResidentialStatuses}
                      onChange={(event) => setResidentialStatus(event)}
                      value={
                        thisLead.residential_status
                          ? thisLead.residential_status
                          : residentialStatus
                      }
                      size="small"
                    />
                  )}
                  {errors.residentialStatus && (
                    <SoftTypography variant="body2" color="error">
                      {errors.residentialStatus}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Date of Birth*
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={thisLead.dob ? thisLead.dob : tempDob}
                    onChange={(e) => setTempDob(e.target.value)}
                    onBlur={() => setDob(tempDob)}
                    size="small"
                    disabled={thisLead.verified_data}
                  />
                  {errors.dob && (
                    <SoftTypography variant="body2" color="error">
                      {errors.dob}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Current Housing*
                  </SoftTypography>
                  {thisLead.current_housing && thisLead.verified_data ? (
                    <SoftInput
                      type="text"
                      value={matchingLabel(
                        HousingTypes,
                        thisLead.current_housing
                      )}
                      size="small"
                      disabled={thisLead.verified_data}
                    />
                  ) : (
                    <SoftSelect
                      options={HousingTypes}
                      onChange={(event) => setHousingType(event)}
                      value={
                        thisLead.current_housing
                          ? thisLead.current_housing
                          : housingType
                      }
                      size="small"
                    />
                  )}
                  {errors.housingType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.housingType}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Location of Housing for Loan*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: null, label: "" },
                      { value: "local", label: "Local" },
                      { value: "overseas", label: "Overseas" },
                    ]}
                    onChange={(event) =>
                      setLoanPropertyLocation({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={
                      loanPropertyLocation
                        ? loanPropertyLocation
                        : matchingOption(
                            [
                              { value: null, label: "" },
                              { value: "local", label: "Local" },
                              { value: "overseas", label: "Overseas" },
                            ],
                            thisLead.loan_property_location
                          )
                    }
                    size="small"
                  />
                  {errors.loanPropertyLocation && (
                    <SoftTypography variant="body2" color="error">
                      {errors.loanPropertyLocation}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Type*
                  </SoftTypography>
                  <SoftSelect
                    options={PropertyTypes}
                    onChange={(event) =>
                      setNewPropertyType({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={
                      setNewPropertyType
                        ? newPropertyType
                        : matchingOption(PropertyTypes, thisLead.new_property)
                    }
                    size="small"
                  />
                  {errors.newPropertyType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.newPropertyType}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Building Under Construction*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      { value: "yes", label: "Yes" },
                      { value: "no", label: "No" },
                    ]}
                    onChange={(event) =>
                      setBuc({ value: event.value, label: event.label })
                    }
                    value={
                      buc
                        ? buc
                        : matchingOption(
                            [
                              { value: "yes", label: "Yes" },
                              { value: "no", label: "No" },
                            ],
                            thisLead.buc
                          )
                    }
                    size="small"
                  />
                  {errors.buc && (
                    <SoftTypography variant="body2" color="error">
                      {errors.buc}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Type of Financing Required*
                  </SoftTypography>
                  <SoftSelect
                    options={[
                      {
                        value: "New Home Purchase",
                        label: "New Home Purchase",
                      },
                      { value: "Refinance", label: "Refinance" },
                      { value: "Cash Out", label: "Cash-out" },
                    ]}
                    onChange={(event) =>
                      setFinancingType({
                        value: event.value,
                        label: event.label,
                      })
                    }
                    value={
                      financingType
                        ? financingType
                        : matchingOption(
                            [
                              {
                                value: "New Home Purchase",
                                label: "New Home Purchase",
                              },
                              { value: "Refinance", label: "Refinance" },
                              { value: "Cash Out", label: "Cash-out" },
                            ],
                            thisLead.financing_type
                          )
                    }
                    size="small"
                  />
                  {errors.financingType && (
                    <SoftTypography variant="body2" color="error">
                      {errors.financingType}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Loan Amount*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={
                      tempLoanAmount ? tempLoanAmount : thisLead.loan_amount
                    }
                    onChange={(e) => setTempLoanAmount(e.target.value)}
                    onBlur={() => setLoanAmount(tempLoanAmount)}
                    size="small"
                  />
                  {errors.loanAmount && (
                    <SoftTypography variant="body2" color="error">
                      {errors.loanAmount}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Tenure Required (Months)*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={tempTenure ? tempTenure : thisLead.tenure_required}
                    onChange={(e) => setTempTenure(e.target.value)}
                    onBlur={() => setTenure(tempTenure)}
                    size="small"
                  />
                  {errors.tenure && (
                    <SoftTypography variant="body2" color="error">
                      {errors.tenure}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Name
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={
                      tempNewPropertyName
                        ? tempNewPropertyName
                        : thisLead.new_property_name
                    }
                    onChange={(e) => setTempNewPropertyName(e.target.value)}
                    onBlur={() => setNewPropertyName(tempNewPropertyName)}
                    size="small"
                  />
                  {errors.newPropertyName && (
                    <SoftTypography variant="body2" color="error">
                      {errors.newPropertyName}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Selected Loan Package*
                  </SoftTypography>
                  <SoftSelect
                    options={loanPackages}
                    onChange={(event) => {
                      setLoanPackage(event);
                      getPackageById(event.value);
                    }}
                    value={loanPackage}
                    style={{ zIndex: 999999 }}
                    size="small"
                  />
                  {errors.loanPackages && (
                    <SoftTypography variant="body2" color="error">
                      {errors.loanPackages}
                    </SoftTypography>
                  )}
                  {loanPackage && (
                    <Grid item xs={12} md={12} xl={12} mt={3}>
                      <Card
                        style={{
                          padding: "20px",
                          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <SoftButton
                          component={""}
                          onClick={() => ViewPackage(activePackage.package_id)}
                          variant="gradient"
                          style={{
                            backgroundImage:
                              "linear-gradient(to top left, #173358, #91d4c7)",
                            color: "white",
                          }}
                          size="large"
                        >
                          {packageNames.find(
                            (p) => p.id == activePackage?.package_name
                          )?.name || "Invalid package"}
                        </SoftButton>
                        <SoftTypography
                          mt={1}
                          variant="caption"
                          style={{ color: "gray" }}
                        >
                          &nbsp;
                        </SoftTypography>
                        <Grid item xs={12} md={12} xl={12}>
                          <SoftBadge
                            badgeContent={activePackage.bank_name}
                            color="primary"
                            style={{ float: "left" }}
                            sx={{ mr: 1 }}
                            container
                          />
                          {activePackage.is_active && (
                            <SoftBadge
                              badgeContent="Active"
                              color="secondary"
                              style={{ float: "left" }}
                              sx={{ mr: 1 }}
                              container
                            />
                          )}
                        </Grid>
                        <SoftTypography mt={1} variant="h6">
                          Year 1:{" "}
                          {activePackage?.y1_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y1_rates,
                                activePackage?.y1_rates_benchmark,
                                activePackage?.y1_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 2:{" "}
                          {activePackage?.y2_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y2_rates,
                                activePackage?.y2_rates_benchmark,
                                activePackage?.y2_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 3:{" "}
                          {activePackage?.y3_rates ? (
                            activePackage?.y3_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y3_rates,
                                activePackage?.y3_rates_benchmark,
                                activePackage?.y3_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 4:{" "}
                          {activePackage?.y4_rates ? (
                            activePackage?.y4_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y4_rates,
                                activePackage?.y4_rates_benchmark,
                                activePackage?.y4_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 5:{" "}
                          {activePackage?.y5_rates ? (
                            activePackage?.y5_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y5_rates,
                                activePackage?.y5_rates_benchmark,
                                activePackage?.y5_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Thereafter:{" "}
                          {activePackage?.thereafter_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.thereafter_rates,
                                activePackage?.thereafter_rates_benchmark,
                                activePackage?.thereafter_rates_cat
                              )}
                        </SoftTypography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} xl={12} mt={3}>
                            {activePackage.legal_subsidy_cash_rebate_amount !==
                            "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Legal Subsidy"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage.fire_insurance_description !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Insurance"
                                mr={1}
                                color="error"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage.free_valuation !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Valuation"
                                mr={1}
                                color="success"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage.free_repricing_conversion !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Repricing"
                                mr={1}
                                color="primary"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          xl={12}
                          mt={3}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SoftButton
                            component={""}
                            onClick={() =>
                              ViewPackage(activePackage.package_id)
                            }
                            variant="gradient"
                            color="error"
                          >
                            View Full Package
                          </SoftButton>
                        </Grid>
                      </Card>
                    </Grid>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Status*
                  </SoftTypography>
                  <SoftSelect
                    options={LeadStatuses}
                    onChange={(event) =>
                      setLeadStatus({ value: event.value, label: event.label })
                    }
                    value={
                      leadStatus
                        ? leadStatus
                        : matchingOption(LeadStatuses, thisLead.status)
                    }
                    size="small"
                  />
                  {errors.leadStatus && (
                    <SoftTypography variant="body2" color="error">
                      {errors.leadStatus}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assigned to*
                  </SoftTypography>
                  <SoftSelect
                    options={consultants}
                    onChange={(event) => setAssignedTo(event.value)}
                    value={assignedTo ? assignedTo.value : ""}
                    size="small"
                  />
                  {errors.assignedTo && (
                    <SoftTypography variant="body2" color="error">
                      {errors.assignedTo}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assign Banker
                  </SoftTypography>
                  <SoftSelect
                    options={bankers}
                    onChange={(event) => setAssignedBanker(event.value)}
                    value={assignedTo ? assignedTo.value : ""}
                    size="small"
                  />
                  {errors.assignedTo && (
                    <SoftTypography variant="body2" color="error">
                      {errors.assignedTo}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Remind on
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={reminder}
                    onChange={(e) => setReminder(e.target.value)}
                    size="small"
                    disabled={thisLead.verified_data}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Documents <br />
                    <SoftTypography variant="caption" color="error">
                      (Only pdf, images & zip/7zip files are accepted)
                    </SoftTypography>
                  </SoftTypography>
                  <SoftDropzone
                    options={options}
                    setDropzoneRef={setDropzoneRef}
                    setFiles={setFiles}
                  />
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={10}>
              <Grid item xs={12} md={6} xl={8}>
                &nbsp;
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => back()}
                      variant="gradient"
                      color="error"
                    >
                      Back
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => add()}
                      variant="gradient"
                      color="info"
                    >
                      Add
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
}

export default NewLead;
