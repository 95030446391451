import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function NotificationModal({ setShowModal, message, isModalRedirect = true }) {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      document.getElementById("notificationModal").style.opacity = 0;
      setShowModal(false);
      isModalRedirect && navigate(-1);
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate, setShowModal]);

  return (
    <div
      id="notificationModal"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        transition: "opacity 1s",
        background: "rgba(0, 0, 0, 0.7)",
        padding: "20px",
        borderRadius: "10px",
        width: "95%",
        height: "95%",
      }}
    >
      <SoftBox>
        <SoftTypography
          variant="h2"
          color="success"
          style={{ marginBottom: "5px" }}
        >
          {message}
        </SoftTypography>
      </SoftBox>
    </div>
  );
}

export default NotificationModal;
