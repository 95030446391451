import React, { useEffect, useState } from "react";
import "./index.css";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <div>
      {isOnline ? null : (
        <div className="popup">
          <h2>Connection Lost</h2>
          <p>You have lost internet connection. Please check your network.</p>
        </div>
      )}
    </div>
  );
};

export default NetworkStatus;
