// layouts/pages/loan-packages

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard PRO React components
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftBadgeDot from "components/SoftBadgeDot";

import axios from "axios";
import SoftTypography from "components/SoftTypography";

import PackageNames from "data/PackageNames";
import { apiURL } from "commons/apiURL";
import SoftBadge from "components/SoftBadge";
import Loading from "components/Loading";

import Banks from "data/Banks";
import HousingTypes from "data/HousingTypes";
import SoftSelect from "components/SoftSelect";

function Packages(props) {
  const navigate = useNavigate();
  const user = props.user;
  const packageId = props.packageId;
  const setPackageId = props.setPackageId;
  const [packageNames, setPackageNames] = useState(PackageNames);
  const [benchmarks, setBenchmarks] = useState([]);
  const [packages, setPackages] = useState([]);

  const [loanSearch, setLoanSearch] = useState("");
  const [isActive, setIsActive] = useState({ value: "All", label: "All" });
  const [bankFilter, setBankFilter] = useState("");
  const [packageNameFilter, setPackageNameFilter] = useState("");

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();

  const checkuserRole = (userRole) => {
    if (userRole.includes("BANKER-")) {
      //banker - to return the bank
      userRole = userRole.replace("BANKER-", "");
      return Banks.find((option) => option.value === userRole).value;
    } else if (userRole === "CONSULTANTS") {
      //consultant
      return userRole.toUpperCase();
    } else if (userRole === "SUPER-USER") {
      //super-user
      return userRole.toUpperCase();
    }
  };

  useEffect(() => {
    checkuserRole(userRole);
  }, []);

  useEffect(() => {
    getPackageNames();
    getBenchmarks();
  }, []);

  const getRateWithBenchmark = (rate, benchmarkValue, rateCategory) => {
    const benchmarkLabel = benchmarks.find(
      (b) => b.rate_type === benchmarkValue
    )?.rate_name;
    if (rate) {
      return `${rate}% ${
        benchmarkLabel ? "+ " + benchmarkLabel : rateCategory.toUpperCase()
      }`;
    } else if (rateCategory == "hybrid") {
      return rateCategory.toUpperCase();
    }
    return null;
  };

  const getPackageNames = async () => {
    try {
      const response = await axios.get(apiURL + "/api/getPackageNames", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      setPackageNames(
        response.data.packageNames.map((item, i) => ({
          value: item.package_name_id,
          label: item.package_name,
        }))
      );
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response?.data?.message
      );
    }
  };

  const getBenchmarks = async () => {
    try {
      const response = await axios.get(apiURL + "/api/getBenchmarks", {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "prata",
          "Content-Type": "application/json",
        },
      });

      setBenchmarks(response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response?.data?.message
      );
    }
  };

  const getPackagesByFilter = async (loanSearch, active, bank, packageName) => {
    let queryParams = "";

    if (loanSearch !== "") {
      queryParams += `?minQuantum=${loanSearch}`;
    }

    if (active !== "") {
      queryParams += `${queryParams ? "&" : "?"}active=${active}`;
    }

    if (bank !== "") {
      queryParams += `${queryParams ? "&" : "?"}bank=${bank}`;
    }

    if (packageName !== "") {
      queryParams += `${queryParams ? "&" : "?"}packageName=${packageName}`;
    }

    try {
      const response = await axios.get(
        apiURL + `/api/getPackagesByFilters${queryParams}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        }
      );
      setPackages(response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
      setPackages([]);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // Regular expression to check if the input is an integer
      setError(false);
      setLoanSearch(value);
      // getPackagesByMinQuantum(value, isActive.value);
      // getPackagesByFilter(value, isActive.value, bankFilter, packageNameFilter);
    } else {
      setError(true);
    }
  };

  const getPackagesByBank = async (bank) => {
    try {
      const response = await axios.get(
        apiURL + `/api/getPackagesByBank/${bank}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setPackages(response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
    }
  };

  const ViewPackage = (thisPackageId) => {
    navigate(`/pages/loan-packages/view/${thisPackageId}`);
    setPackageId(thisPackageId);
  };

  const DuplicatePackageCalls = async (newPackage) => {
    try {
      const response = await axios.post(
        apiURL + "/api/addPackage",
        newPackage,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Packages added successfully:", response.data);
      console.log(newPackage);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response.data.message
      );
    }
  };

  // Duplicate Package
  const DuplicatePackage = (thisPackageId) => {
    setIsLoading(true);
    const packageToBeDuplicated = packages.filter(
      (p) => p.package_id === thisPackageId
    )[0];
    setTimeout(() => {
      console.log(packageToBeDuplicated);
      console.log(thisPackageId);
      DuplicatePackageCalls(packageToBeDuplicated);
      setTimeout(() => {
        getPackagesByFilter();
        setIsLoading(false);
      }, 777);
    }, 333);
  };

  const checkLoanType = (loanType) => {
    if (loanType.toLowerCase() === "new home purchase") {
      return "New Home Purchase";
    } else if (loanType.toLowerCase() === "refinance") {
      return "Refinance";
    } else {
      return "Cash-Out";
    }
  };

  const CheckHousingTypes = (housingType) => {
    let res = "";
    HousingTypes.map((h) => {
      if (h.value === housingType) {
        res = h.label;
      }
    });
    return res;
  };

  useEffect(() => {
    if (
      checkuserRole(userRole) !== "SUPER-USER" &&
      checkuserRole(userRole) !== "CONSULTANTS"
    ) {
      const bank = checkuserRole(userRole);
      getPackagesByBank(bank);
    } else {
      // getPackagesByMinQuantum("", isActive.value);
      // getPackagesByFilter("", isActive.value, bankFilter, packageNameFilter);
    }
  }, []);

  useEffect(() => {
    getPackagesByFilter(
      loanSearch,
      isActive.value,
      bankFilter,
      packageNameFilter
    );
  }, [loanSearch, isActive, bankFilter, packageNameFilter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!isLoading ? (
        <SoftBox mt={2} mb={2}>
          <Grid container>
            <Grid
              container
              spacing={3}
              style={{ position: "relative", zIndex: "10" }}
            >
              <Grid item xl={4}>
                <SoftInput
                  size="large"
                  icon={{ component: "search", direction: "left" }}
                  placeholder="Filter By Loan Amount..."
                  value={loanSearch}
                  onChange={(e) => handleInputChange(e)}
                  // Add additional styles or classes based on the error state
                  className={error ? "input-error" : ""}
                />
                {error && (
                  <SoftTypography variant="body2" color="error">
                    Please enter a valid number value with no decimals.
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xl={2}>
                <SoftSelect
                  value={isActive}
                  options={[
                    { value: "All", label: "All" },
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                  onChange={(e) => {
                    setIsActive(e);
                  }}
                  size="large"
                />
              </Grid>
              <Grid item xl={2}>
                <SoftSelect
                  defaultValue={{ value: "", label: "All Banks" }}
                  placeholder="Select Bank"
                  options={[{ value: "", label: "All" }, ...Banks]}
                  onChange={(e) => {
                    setBankFilter(e.value);
                  }}
                  size="large"
                />
              </Grid>
              <Grid item xl={2}>
                <SoftSelect
                  placeholder="Select Package Name"
                  defaultValue={{ value: "", label: "All Package Names" }}
                  options={[{ value: "", label: "All" }, ...packageNames]}
                  onChange={(e) => {
                    setPackageNameFilter(e.value);
                  }}
                  size="large"
                />
              </Grid>
              <Grid item xl={2}>
                <SoftButton
                  component={""}
                  onClick={() => navigate("/pages/loan-packages/add")}
                  variant="gradient"
                  color="info"
                  style={{
                    float: "right",
                    backgroundImage:
                      "linear-gradient(to top left, #173358, #91d4c7)",
                    color: "white",
                  }}
                >
                  New Package
                </SoftButton>
              </Grid>
            </Grid>
            {packages.length > 0 ? (
              <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
                {packages.map((activePackage) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    key={activePackage.package_id}
                  >
                    <Card
                      style={{
                        padding: "20px",
                        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <SoftButton
                        component={""}
                        onClick={() => ViewPackage(activePackage.package_id)}
                        variant="gradient"
                        style={{
                          backgroundImage:
                            "linear-gradient(to top left, #173358, #91d4c7)",
                          color: "white",
                        }}
                        size="large"
                      >
                        {
                          PackageNames.find(
                            (p) => p.value === activePackage.package_name
                          )?.label
                        }
                        - {activePackage.bank_name} -{" "}
                        {CheckHousingTypes(activePackage.property_type)}
                      </SoftButton>
                      <SoftTypography
                        mt={1}
                        variant="caption"
                        style={{ color: "gray" }}
                      >
                        &nbsp;
                      </SoftTypography>
                      <Grid item xs={12} md={12} xl={12}>
                        <SoftBadge
                          badgeContent={activePackage.bank_name}
                          color="primary"
                          style={{ float: "left" }}
                          sx={{ mr: 1 }}
                          container
                        />
                        {activePackage.is_active ? (
                          <SoftBadge
                            badgeContent="Active"
                            color="secondary"
                            style={{ float: "left" }}
                            sx={{ mr: 1 }}
                            container
                          />
                        ) : (
                          <SoftBadge
                            badgeContent="Inactive"
                            color="error"
                            style={{ float: "left" }}
                            sx={{ mr: 1 }}
                            container
                          />
                        )}
                      </Grid>
                      <SoftTypography mt={1} variant="h6">
                        Min Loan Amount: $
                        {parseInt(activePackage.min_quantum).toLocaleString(
                          "en-US"
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        Loan Type: {checkLoanType(activePackage.package_type)}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        Year 1:{" "}
                        {getRateWithBenchmark(
                          activePackage.y1_rates,
                          activePackage.y1_rates_benchmark,
                          activePackage.y1_rates_cat
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        Year 2:{" "}
                        {getRateWithBenchmark(
                          activePackage.y2_rates,
                          activePackage.y2_rates_benchmark,
                          activePackage.y2_rates_cat
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        {activePackage.y3_rates ||
                        activePackage.y3_rates_cat == "hybrid" ? (
                          "Year 3: " +
                          getRateWithBenchmark(
                            activePackage.y3_rates,
                            activePackage.y3_rates_benchmark,
                            activePackage.y3_rates_cat
                          )
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        {activePackage.y4_rates ||
                        activePackage.y4_rates_cat == "hybrid" ? (
                          "Year 4: " +
                          getRateWithBenchmark(
                            activePackage.y4_rates,
                            activePackage.y4_rates_benchmark,
                            activePackage.y4_rates_cat
                          )
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        {activePackage.y5_rates ||
                        activePackage.y5_rates_cat == "hybrid" ? (
                          "Year 5: " +
                          getRateWithBenchmark(
                            activePackage.y5_rates,
                            activePackage.y5_rates_benchmark,
                            activePackage.y5_rates_cat
                          )
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </SoftTypography>
                      <SoftTypography variant="h6">
                        Thereafter:{" "}
                        {getRateWithBenchmark(
                          activePackage.thereafter_rates,
                          activePackage.thereafter_rates_benchmark,
                          activePackage.thereafter_rates_cat
                        )}
                      </SoftTypography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} xl={12} mt={3}>
                          {activePackage.legal_subsidy_cash_rebate !== 0 ? (
                            <SoftBadgeDot
                              badgeContent="Free Legal Subsidy"
                              style={{ float: "left" }}
                            />
                          ) : (
                            <span>&nbsp;</span>
                          )}
                          {activePackage.fire_insurance !== 0 ? (
                            <SoftBadgeDot
                              badgeContent="Free Fire Insurance"
                              mr={1}
                              color="error"
                              style={{ float: "left" }}
                            />
                          ) : (
                            <span>&nbsp;</span>
                          )}
                          {activePackage.free_valuation !== "" ? (
                            <SoftBadgeDot
                              badgeContent="Free Valuation"
                              mr={1}
                              color="success"
                              style={{ float: "left" }}
                            />
                          ) : (
                            <span>&nbsp;</span>
                          )}
                          {activePackage.free_repricing_conversion == 1 ? (
                            <SoftBadgeDot
                              badgeContent="Free Repricing"
                              mr={1}
                              color="primary"
                              style={{ float: "left" }}
                            />
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        xl={12}
                        mt={3}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <SoftButton
                          component={""}
                          style={{ marginRight: 7 }}
                          onClick={() => ViewPackage(activePackage.package_id)}
                          variant="gradient"
                          color="error"
                        >
                          View Full Package
                        </SoftButton>
                        <SoftButton
                          component={""}
                          onClick={() =>
                            DuplicatePackage(activePackage.package_id)
                          }
                          variant="gradient"
                          color="success"
                        >
                          Duplicate Package
                        </SoftButton>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12} md={6} xl={4}>
                <Card sx={{ marginTop: "1rem", height: "120px" }}>
                  <SoftBox sx={{ padding: "20px" }}>
                    <SoftTypography variant="h4">
                      No Packages for Selection
                    </SoftTypography>
                  </SoftBox>
                </Card>
              </Grid>
            )}
          </Grid>
        </SoftBox>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
}

export default Packages;
