// src/layouts/pages/loan-packages/add/index.js
import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loading from "components/Loading";

// @mui material components
import { Grid, Card, Tooltip } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftBadgeDot from "components/SoftBadgeDot";
import Document from "examples/Icons/Document";

import PropertyTypes from "data/PropertyTypes";
import Nationalities from "data/Nationalities";
import ResidentialStatuses from "data/ResidentialStatuses";
import HousingTypes from "data/HousingTypes";

import axios from "axios";
import NotificationModal from "components/NotificationModal";

// Sweet Alerts page components
import { apiURL } from "commons/apiURL";
import LeadStatuses from "data/LeadStatuses";
import * as api from "api";

function ViewLead(props) {
  const user = props.user;
  const { leadId } = useParams();
  const navigate = useNavigate();

  // States
  const [showModal, setShowModal] = useState(false);
  const [packageNames, setPackageNames] = useState([]);
  const [benchmarks, setBenchmarks] = useState([]);
  const [errors, setErrors] = useState({});
  const [thisLead, setThisLead] = useState({});
  const [activePackage, setActivePackage] = useState({});
  const [name, setName] = useState("");
  const [tempName, setTempName] = useState("");
  const [mobile, setMobile] = useState("");
  const [tempMobile, setTempMobile] = useState("");
  const [email, setEmail] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [dob, setDob] = useState("");
  const [tempDob, setTempDob] = useState("");
  const [loanAmount, setLoanAmount] = useState("");
  const [tempLoanAmount, setTempLoanAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [tempTenure, setTempTenure] = useState("");
  const [newPropertyName, setNewPropertyName] = useState("");
  const [tempNewPropertyName, setTempNewPropertyName] = useState("");
  const [consultants, setConsultants] = useState([]);
  const [bankers, setBankers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let userRole = user["https://sales.loansupermart.sg/roles"][0].toUpperCase();
  userRole = userRole.replace("BANKER-", "");

  useEffect(() => {
    getLeadById();
    getConsultants();
    getBankers();
    getPackageNames();
    getBenchmarks();
  }, []);

  const getPackageNames = async (page) => {
    try {
      const data = await api.getPackageNames();
      console.log("API Response:", data);
      if (data) {
        setPackageNames(
          data.packageNames.map((item) => ({
            id: item.package_name_id,
            name: item.package_name,
          }))
        );
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getBenchmarks = async (page) => {
    try {
      const data = await api.getBenchmarks();
      console.log("API Response:", data);
      if (data) {
        setBenchmarks((prev) => [
          ...data.map((item, i) => ({
            value: item.rate_type,
            label: item.rate_name,
          })),
        ]);
      } else {
        console.error("Unexpected API response format", data);
      }
    } catch (error) {
      console.error("Failed to fetch package names:", error);
    }
  };

  const getConsultants = useCallback(async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getConsultants`);
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setConsultants(cns);
      if (cns.length > 0) {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  }, []);

  const getBankers = useCallback(async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getBankers`);
      console.log("Lead found successfully:", response.data);
      let cns = [];
      response.data.forEach((c) => {
        cns.push({ value: c.user_id, label: c.name });
      });
      setBankers(cns);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  }, []);

  const getLeadById = useCallback(async () => {
    try {
      const response = await axios.get(`${apiURL}/api/getLead/${leadId}`, {
        headers: {
          "X-API-KEY": process.env.REACT_APP_API_KEY,
          "x-app-id": "laksa",
          "Content-Type": "application/json",
        },
      });
      setThisLead(response.data);
      getPackageById(response.data.loan_package);
      console.log("Lead found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  }, [thisLead]);

  const getPackageById = async (packageId) => {
    try {
      const response = await axios.get(
        `${apiURL}/api/getPackage/${packageId}`,
        {
          headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY,
            "x-app-id": "laksa",
            "Content-Type": "application/json",
          },
        }
      );
      setActivePackage(response.data[0]);
      console.log("Package found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const Gender = [
    { value: "FEMALE", label: "Female" },
    { value: "MALE", label: "Male" },
    { value: "UNKNOWN", label: "Unknown" },
  ];

  const matchingLabel = (obj, value) => {
    return obj.find((option) => option.value === value)?.label;
  };

  const getRateWithBenchmark = (rate, benchmarkValue, rateCategory) => {
    const benchmarkLabel = benchmarks.find(
      (b) => b.value === benchmarkValue
    )?.label;
    if (rate) {
      if (rateCategory.toUpperCase() === "FIXED") {
        return `${rate}% ${benchmarkLabel || rateCategory.toUpperCase()}`;
      }
      return `${rate}% + ${benchmarkLabel || rateCategory.toUpperCase()}`;
    }
    return null;
  };

  const ViewPackage = (thisPackageId) => {
    navigate(`/pages/loan-packages/view/${thisPackageId}`);
  };

  const ViewDocument = async (objectName) => {
    console.log(objectName);
    try {
      const response = await axios.get(`${apiURL}/api/getObject/${objectName}`);
      console.log(response.data);
      window.open(response.data.url, "_blank");
      console.log("Doc found successfully:", response.data);
    } catch (error) {
      console.error(
        "There was an error sending the request:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    console.log("This Lead:", thisLead);
  }, [thisLead]);

  return (
    <DashboardLayout>
      <DashboardNavbar absolutedisabled={true} />
      {showModal && (
        <NotificationModal
          setShowModal={setShowModal}
          message={"Updated Successfully"}
          disabled={true}
        />
      )}
      {!isLoading ? (
        <SoftBox mt={5} mb={3}>
          <Card style={{ padding: "28px 40px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12} mb={1}>
                <Grid item xs={12} md={12} xl={12}>
                  {thisLead.verified_data ? (
                    <SoftBadge
                      badgeContent="MyInfo Data"
                      color="success"
                      style={{ float: "left" }}
                      sx={{ mr: 1 }}
                      container
                    />
                  ) : (
                    <SoftBadge
                      badgeContent="Self-input Data"
                      color="secondary"
                      style={{ float: "left" }}
                      sx={{ mr: 1 }}
                      container
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Name*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.name ? thisLead.name : tempName}
                    onChange={(e) => setTempName(e.target.value)}
                    onBlur={() => setName(tempName)}
                    size="small"
                    disabled={true}
                  />
                  {errors.name && (
                    <SoftTypography variant="body2" color="error">
                      {errors.name}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Mobile Number*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.mobile ? thisLead.mobile : tempMobile}
                    onChange={(e) => setTempMobile(e.target.value)}
                    onBlur={() => setMobile(tempMobile)}
                    size="small"
                    disabled={true}
                  />
                  {errors.mobile && (
                    <SoftTypography variant="body2" color="error">
                      {errors.mobile}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Email*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={thisLead.email ? thisLead.email : tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                    onBlur={() => setEmail(tempEmail)}
                    size="small"
                    disabled={true}
                  />
                  {errors.email && (
                    <SoftTypography variant="body2" color="error">
                      {errors.email}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Date of Birth*
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={thisLead.dob ? thisLead.dob : tempDob}
                    onChange={(e) => setTempDob(e.target.value)}
                    onBlur={() => setDob(tempDob)}
                    size="small"
                    disabled={true}
                  />
                  {errors.dob && (
                    <SoftTypography variant="body2" color="error">
                      {errors.dob}
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Gender*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(Gender, thisLead.gender)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Nationality*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(Nationalities, thisLead.nationality)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Residential Status*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(
                      ResidentialStatuses,
                      thisLead.residential_status
                    )}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                {thisLead.current_housing !== 0 && (
                  <SoftBox mb={2}>
                    <SoftTypography
                      variant="h6"
                      style={{ marginBottom: "5px" }}
                    >
                      Current Housing*
                    </SoftTypography>

                    <SoftInput
                      type="text"
                      value={matchingLabel(
                        HousingTypes,
                        parseInt(thisLead.current_housing)
                      )}
                      size="small"
                      disabled={true}
                    />
                  </SoftBox>
                )}
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Location of Housing for Loan*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(
                      [
                        { value: null, label: "" },
                        { value: "local", label: "Local" },
                        { value: "overseas", label: "Overseas" },
                      ],
                      thisLead.loan_property_location
                    )}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Type*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(
                      PropertyTypes,
                      parseInt(thisLead.new_property)
                    )}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Building Under Construction*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(
                      [
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                        { value: "any", label: "Any" },
                      ],
                      thisLead.buc
                    )}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Type of Financing Required*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(
                      [
                        {
                          value: "New Home Purchase",
                          label: "New Home Purchase",
                        },
                        { value: "Refinance", label: "Refinance" },
                        { value: "Cash Out", label: "Cash-out" },
                      ],
                      thisLead.financing_type
                    )}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Loan Amount*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={
                      tempLoanAmount ? tempLoanAmount : thisLead.loan_amount
                    }
                    onChange={(e) => setTempLoanAmount(e.target.value)}
                    onBlur={() => setLoanAmount(tempLoanAmount)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Tenure Required (Months)*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={tempTenure ? tempTenure : thisLead.tenure_required}
                    onChange={(e) => setTempTenure(e.target.value)}
                    onBlur={() => setTenure(tempTenure)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    New Property Name
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={
                      tempNewPropertyName
                        ? tempNewPropertyName
                        : thisLead.new_property_name === "Skip Question"
                        ? ""
                        : thisLead.new_property_name
                    }
                    onChange={(e) => setTempNewPropertyName(e.target.value)}
                    onBlur={() => setNewPropertyName(tempNewPropertyName)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Status*
                  </SoftTypography>
                  {thisLead.status && (
                    <SoftInput
                      type="text"
                      value={matchingLabel(LeadStatuses, thisLead.status)}
                      size="small"
                      disabled={true}
                    />
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assigned to*
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(consultants, thisLead.assigned_to)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Assigned Banker
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    value={matchingLabel(bankers, thisLead.assigned_banker)}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Remind on
                  </SoftTypography>
                  <SoftInput
                    type="date"
                    value={thisLead.reminder ? thisLead.reminder : ""}
                    size="small"
                    disabled={true}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Selected Loan Package*
                  </SoftTypography>
                  {thisLead.loan_package ? (
                    <Grid item xs={12} md={12} xl={12}>
                      <Card
                        style={{
                          padding: "20px",
                          boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <SoftButton
                          component={""}
                          onClick={() => ViewPackage(activePackage.package_id)}
                          variant="gradient"
                          style={{
                            backgroundImage:
                              "linear-gradient(to top left, #173358, #91d4c7)",
                            color: "white",
                          }}
                          size="large"
                        >
                          {packageNames.find(
                            (p) => p.id == activePackage?.package_name
                          )?.name || "Invalid package"}
                        </SoftButton>
                        <SoftTypography
                          mt={1}
                          variant="caption"
                          style={{ color: "gray" }}
                        >
                          &nbsp;
                        </SoftTypography>
                        <Grid item xs={12} md={12} xl={12}>
                          <SoftBadge
                            badgeContent={activePackage?.bank_name}
                            color="primary"
                            style={{ float: "left" }}
                            sx={{ mr: 1 }}
                            container
                          />
                          {activePackage?.is_active == 1 && (
                            <SoftBadge
                              badgeContent="Active"
                              color="secondary"
                              style={{ float: "left" }}
                              sx={{ mr: 1 }}
                              container
                            />
                          )}
                        </Grid>
                        <SoftTypography mt={1} variant="h6">
                          Year 1:{" "}
                          {activePackage?.y1_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y1_rates,
                                activePackage?.y1_rates_benchmark,
                                activePackage?.y1_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 2:{" "}
                          {activePackage?.y2_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.y2_rates,
                                activePackage?.y2_rates_benchmark,
                                activePackage?.y2_rates_cat
                              )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 3:{" "}
                          {activePackage?.y3_rates ? (
                            activePackage?.y3_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y3_rates,
                                activePackage?.y3_rates_benchmark,
                                activePackage?.y3_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 4:{" "}
                          {activePackage?.y4_rates ? (
                            activePackage?.y4_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y4_rates,
                                activePackage?.y4_rates_benchmark,
                                activePackage?.y4_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Year 5:{" "}
                          {activePackage?.y5_rates ? (
                            activePackage?.y5_rates_cat == "hybrid" ? (
                              "HYBRID"
                            ) : (
                              getRateWithBenchmark(
                                activePackage?.y5_rates,
                                activePackage?.y5_rates_benchmark,
                                activePackage?.y5_rates_cat
                              )
                            )
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </SoftTypography>
                        <SoftTypography variant="h6">
                          Thereafter:{" "}
                          {activePackage?.thereafter_rates_cat == "hybrid"
                            ? "HYBRID"
                            : getRateWithBenchmark(
                                activePackage?.thereafter_rates,
                                activePackage?.thereafter_rates_benchmark,
                                activePackage?.thereafter_rates_cat
                              )}
                        </SoftTypography>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12} xl={12} mt={3}>
                            {activePackage?.legal_subsidy_cash_rebate_amount !==
                            "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Legal Subsidy"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.fire_insurance_description !==
                            "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Insurance"
                                mr={1}
                                color="error"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.free_valuation !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Fire Valuation"
                                mr={1}
                                color="success"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                            {activePackage?.free_repricing_conversion !== "" ? (
                              <SoftBadgeDot
                                badgeContent="Free Repricing"
                                mr={1}
                                color="primary"
                                style={{ float: "left" }}
                              />
                            ) : (
                              <span>&nbsp;</span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          xl={12}
                          mt={3}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SoftButton
                            component={""}
                            onClick={() =>
                              ViewPackage(activePackage.package_id)
                            }
                            variant="gradient"
                            color="error"
                          >
                            View Full Package
                          </SoftButton>
                        </Grid>
                      </Card>
                    </Grid>
                  ) : (
                    <SoftTypography variant="caption">
                      No Package Selected
                    </SoftTypography>
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" style={{ marginBottom: "5px" }}>
                    Documents{" "}
                    <SoftTypography variant="caption" color="error">
                      (Allow pop-up windows to view files)
                    </SoftTypography>
                  </SoftTypography>
                  {thisLead && thisLead.docs_uploaded ? (
                    //It's a string coming from backend
                    thisLead.docs_uploaded.length > 0 ? (
                      thisLead.docs_uploaded.map((doc, i) => (
                        <div
                          key={i}
                          onClick={() => ViewDocument(doc)}
                          style={{
                            display: "inline-flex",
                            marginRight: "7px",
                            cursor: "pointer",
                          }}
                        >
                          <Tooltip title={doc}>
                            <div>
                              <Document key={doc} size="30px" color="success" />
                            </div>
                          </Tooltip>
                        </div>
                      ))
                    ) : (
                      <SoftTypography variant="caption">
                        No Documents Uploaded Yet
                      </SoftTypography>
                    )
                  ) : null}
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={8}>
                &nbsp;
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() => back()}
                      variant="gradient"
                      color="error"
                    >
                      Back
                    </SoftButton>
                  </Grid>
                  <Grid item>
                    <SoftButton
                      component={""}
                      onClick={() =>
                        navigate(`/pages/leads/edit/${thisLead.lead_id}`)
                      }
                      variant="gradient"
                      color="info"
                    >
                      Edit
                    </SoftButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  );
}

export default ViewLead;
