import React, { useRef, useState } from 'react';
import './styles/UploadModal.css';

const UploadModal = ({  onUpload, setFiles }) => {
    const [dragOver, setDragOver] = useState(false);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [filenames, setFilenames] = useState([]);
    const handleUploadSuccess = () => {
        setUploadSuccess(true);
        setTimeout(() => { 
            setUploadSuccess(false); // Hide Upload success message after 2 seconds
        }, 2000);
    }

    const handleFileChange = (e) => {
        setIsLoading(true);
        const files = e.target.files;
        setFilenames(files);
        onUpload(files)
            .then(() => {
                handleUploadSuccess();
            })
            .finally(() => setIsLoading(false));
    };

    const onDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const onDragLeave = () => {
        setDragOver(false);
    };

    const onDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        console.log(e)
        setIsLoading(true);
        const files = Array.from(e.dataTransfer.files);
        setFilenames(files);
        onUpload(files)
        .then(() => {
            handleUploadSuccess();
        })
        .finally(() => setIsLoading(false));
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div className="modal-content">
            <div 
                className={`dropzone ${dragOver ? 'dragover' : ''}`}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                onClick={openFileDialog}
            >
                <p className='drag-text'>Drag & Drop files here or click to select</p>
            </div>
            <input 
                type="file" 
                multiple 
                ref={fileInputRef}
                style={{ display: 'none' }} 
                onChange={handleFileChange}
                accept=".png,.jpg,.jpeg,.heic,.pdf"
            />
            {filenames.length > 0 && 
                <div className="filenames">
                    {filenames && Array.from(filenames).map((file, index) => (
                        <li key={index}>{file.name} - {(file.size/1000000).toFixed(2)} mb</li>
                    ))}
                </div>
            }
        </div>
    );
};

export default UploadModal;
