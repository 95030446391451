const PropertyTypes = [
  { value: 999, label: "N.A." },
  { value: 998, label: "ANY RESIDENTIAL" },
  { value: 997, label: "COMMERCIAL" },
  { value: 996, label: "OVERSEAS PROPERTY" },
  { value: 111, label: "1-ROOM FLAT (HDB)" },
  { value: 112, label: "2-ROOM FLAT (HDB)" },
  { value: 113, label: "3-ROOM FLAT (HDB)" },
  { value: 114, label: "4-ROOM FLAT (HDB)" },
  { value: 115, label: "5-ROOM FLAT (HDB)" },
  { value: 116, label: "EXECUTIVE FLAT (HDB)" },
  { value: 118, label: "STUDIO APARTMENT (HDB)" },
  { value: 121, label: "DETACHED HOUSE" },
  { value: 122, label: "SEMI-DETACHED HOUSE" },
  { value: 123, label: "TERRACE HOUSE" },
  { value: 131, label: "CONDOMINIUM" },
  { value: 132, label: "EXECUTIVE CONDOMINIUM" },
  { value: 139, label: "APARTMENT" },
];

export default PropertyTypes;
