import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "components/SoftDropzone/SoftDropzoneRoot";

import { apiURL } from "commons/apiURL";

function SoftDropzone({ setDropzoneRef, options, setFiles }) {
  const dropzoneRef = useRef();
  let filesArray = [];

  useEffect(() => {
    Dropzone.autoDiscover = false;
    console.log('triggered')
    function createDropzone() {
      const dropzone = new Dropzone(dropzoneRef.current, { ...options,
        renameFile: function (file) {
          const date = new Date();
          const datetime = date.toISOString().replace(/:/g, '-'); // Replace ':' to avoid filename issues
          const fileExtension = file.name.split('.').pop();
          let stripped = file.name.replace(/[^a-zA-Z0-9 ]/g, '');
          stripped = stripped.replace(/ /g, '-');
          stripped = stripped.substring(0, 12);
          const newFilename = `${stripped}-${datetime}.${fileExtension}`;
          file.uploadFilename = newFilename;
          console.log(newFilename)
          return newFilename;
        }, 
      });
      // Event listener for when a file is successfully uploaded
      dropzone.on("success", function (file, response) {
        if (response.files) {
          response.files.forEach((uploadedFile) => {
            filesArray.push(uploadedFile.filename)
          });
        }
        setFiles(filesArray)
      });
      
      return dropzone;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    const dropzoneInstance = createDropzone();
    setDropzoneRef(dropzoneInstance); 

    return () => removeDropzone();
  }, [options]);

  return (
    <SoftDropzoneRoot
      component="form"
      action={apiURL + "/api/file-upload"}
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SoftBox className="fallback">
        <SoftBox component="input" name="file" type="file" multiple />
      </SoftBox>
    </SoftDropzoneRoot>
  );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
  setDropzoneRef: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SoftDropzone;
