const Nationalities = [
    { value: 'AD', label: 'ANDORRAN' },
    { value: 'AE', label: 'UNITED ARAB EMIRATES' },
    { value: 'AF', label: 'AFGHAN' },
    { value: 'AG', label: 'ANTIGUAN' },
    { value: 'AI', label: 'ANGUILLAN' },
    { value: 'AL', label: 'ALBANIAN' },
    { value: 'AM', label: 'ARMENIAN' },
    { value: 'AN', label: 'NETHERLANDS ANTILILLES' },
    { value: 'AO', label: 'ANGOLAN' },
    { value: 'AQ', label: 'ANTARCTICA' },
    { value: 'AR', label: 'ARGENTINIAN' },
    { value: 'AS', label: 'AMERICAN SAMOAN' },
    { value: 'AT', label: 'AUSTRIAN' },
    { value: 'AU', label: 'AUSTRALIAN' },
    { value: 'AZ', label: 'AZERBAIJANI' },
    { value: 'BA', label: 'BOSNIAN' },
    { value: 'BB', label: 'BARBADOS' },
    { value: 'BD', label: 'BANGLADESHI' },
    { value: 'BE', label: 'BELGIAN' },
    { value: 'BF', label: 'BURKINABE' },
    { value: 'BG', label: 'BULGARIAN' },
    { value: 'BH', label: 'BAHRAINI' },
    { value: 'BI', label: 'BURUNDIAN' },
    { value: 'BJ', label: 'BENINESE' },
    { value: 'BL', label: 'BELARUSSIAN' },
    { value: 'BN', label: 'BRUNEIAN' },
    { value: 'BO', label: 'BOLIVIAN' },
    { value: 'BR', label: 'BRAZILIAN' },
    { value: 'BS', label: 'BAHAMIAN' },
    { value: 'BT', label: 'BHUTANESE' },
    { value: 'BU', label: 'MYANMAR' },
    { value: 'BW', label: 'BOTSWANA' },
    { value: 'BZ', label: 'BELIZEAN' },
    { value: 'CA', label: 'CANADIAN' },
    { value: 'CB', label: 'CROATIAN' },
    { value: 'CD', label: 'DEMOCRATIC REPUBLIC OF THE CONGO' },
    { value: 'CF', label: 'CENTRAL AFRICAN REPUBLIC' },
    { value: 'CG', label: 'CONGOLESE' },
    { value: 'CH', label: 'SWISS' },
    { value: 'CI', label: 'IVORY COAST' },
    { value: 'CK', label: 'COOK ISLANDER' },
    { value: 'CL', label: 'CHILEAN' },
    { value: 'CM', label: 'CAMEROONIAN' },
    { value: 'CN', label: 'CHINESE' },
    { value: 'CO', label: 'COLOMBIAN' },
    { value: 'CR', label: 'COSTA RICAN' },
    { value: 'CS', label: 'CZECHOSLOVAK' },
    { value: 'CU', label: 'CUBAN' },
    { value: 'CV', label: 'CAPE VERDEAN' },
    { value: 'CY', label: 'CYPRIOT' },
    { value: 'CZ', label: 'CZECH' },
    { value: 'DD', label: 'GERMAN, EAST' },
    { value: 'DE', label: 'GERMAN, WEST' },
    { value: 'DG', label: 'GERMAN' },
    { value: 'DJ', label: 'DJIBOUTIAN' },
    { value: 'DK', label: 'DANISH' },
    { value: 'DM', label: 'DOMINICAN' },
    { value: 'DO', label: 'DOMINICAN (REPUBLIC)' },
    { value: 'DZ', label: 'ALGERIAN' },
    { value: 'EC', label: 'ECUADORIAN' },
    { value: 'EG', label: 'EGYPTIAN' },
    { value: 'EH', label: 'SAHRAWI' },
    { value: 'EN', label: 'ESTONIAN' },
    { value: 'ER', label: 'ERITREAN' },
    { value: 'ES', label: 'SPANISH' },
    { value: 'ET', label: 'ETHIOPIAN' },
    { value: 'FI', label: 'FINNISH' },
    { value: 'FJ', label: 'FIJIAN' },
    { value: 'FR', label: 'FRENCH' },
    { value: 'GA', label: 'GABON' },
    { value: 'GB', label: 'BRITISH' },
    { value: 'GC', label: 'BRITISH OVERSEAS TERRITORIES CITIZEN' },
    { value: 'GD', label: 'GRENADIAN' },
    { value: 'GE', label: 'BRITISH OVERSEAS CITIZEN' },
    { value: 'GF', label: 'FRENCH GUIANESE' },
    { value: 'GG', label: 'BRITISH NATIONAL OVERSEAS' },
    { value: 'GH', label: 'GHANAIAN' },
    { value: 'GJ', label: 'BRITISH PROTECTED PERSON' },
    { value: 'GM', label: 'GAMBIAN' },
    { value: 'GN', label: 'GUINEAN' },
    { value: 'GO', label: 'GEORGIAN' },
    { value: 'GP', label: 'GUADELOUPIAN' },
    { value: 'GQ', label: 'EQUATORIAL GUINEA' },
    { value: 'GR', label: 'GREEK' },
    { value: 'GT', label: 'GUATEMALAN' },
    { value: 'GU', label: 'GUAMANIAN' },
    { value: 'GW', label: 'GUINEAN (BISSAU)' },
    { value: 'GY', label: 'GUYANESE' },
    { value: 'HK', label: 'CHINESE/HONGKONG SAR' },
    { value: 'HN', label: 'HONDURAN' },
    { value: 'HT', label: 'HAITIAN' },
    { value: 'HU', label: 'HUNGARIAN' },
    { value: 'HV', label: 'UPPER VOLTA' },
    { value: 'ID', label: 'INDONESIAN' },
    { value: 'IE', label: 'IRISH' },
    { value: 'IL', label: 'ISRAELI' },
    { value: 'IN', label: 'INDIAN' },
    { value: 'IQ', label: 'IRAQI' },
    { value: 'IR', label: 'IRANIAN' },
    { value: 'IS', label: 'ICELANDER' },
    { value: 'IT', label: 'ITALIAN' },
    { value: 'JM', label: 'JAMAICAN' },
    { value: 'JO', label: 'JORDANIAN' },
    { value: 'JP', label: 'JAPANESE' },
    { value: 'KA', label: 'CAMBODIAN' },
    { value: 'KE', label: 'KENYAN' },
    { value: 'KG', label: 'KIRGHIZ' },
    { value: 'KH', label: 'KAMPUCHEAN' },
    { value: 'KI', label: 'KIRIBATI' },
    { value: 'KM', label: 'COMORAN' },
    { value: 'KP', label: 'D.P.R. KOREAN' },
    { value: 'KR', label: 'KOREAN, SOUTH' },
    { value: 'KS', label: 'KYRGHIS' },
    { value: 'KV', label: 'KOSOVAR' },
    { value: 'KW', label: 'KUWAITI' },
    { value: 'KY', label: 'KYRGYZSTAN' },
    { value: 'KZ', label: 'KAZAKHSTANI' },
    { value: 'LA', label: 'LAOTIAN' },
    { value: 'LB', label: 'LEBANESE' },
    { value: 'LC', label: 'ST. LUCIA' },
    { value: 'LH', label: 'LITHUANIAN' },
    { value: 'LI', label: 'LIECHTENSTEINER' },
    { value: 'LK', label: 'SRI LANKAN' },
    { value: 'LR', label: 'LIBERIAN' },
    { value: 'LS', label: 'LESOTHO' },
    { value: 'LU', label: 'LUXEMBOURGER' },
    { value: 'LV', label: 'LATVIAN' },
    { value: 'LY', label: 'LIBYAN' },
    { value: 'MA', label: 'MOROCCAN' },
    { value: 'MB', label: 'MACEDONIAN' },
    { value: 'MC', label: 'MONACAN' },
    { value: 'MD', label: 'MOLDAVIAN' },
    { value: 'MF', label: 'MICRONESIAN' },
    { value: 'MG', label: 'MADAGASY' },
    { value: 'MH', label: 'MARSHALLESE' },
    { value: 'MJ', label: 'MONTENEGRIN' },
    { value: 'ML', label: 'MALIAN' },
    { value: 'MN', label: 'MONGOLIAN' },
    { value: 'MO', label: 'CHINESE/MACAO SAR' },
    { value: 'MQ', label: 'MARTINIQUAIS' },
    { value: 'MR', label: 'MAURITANEAN' },
    { value: 'MS', label: 'MONTENEGRIN' },
    { value: 'MT', label: 'MALTESE' },
    { value: 'MU', label: 'MAURITIAN' },
    { value: 'MV', label: 'MALDIVIAN' },
    { value: 'MW', label: 'MALAWIAN' },
    { value: 'MX', label: 'MEXICAN' },
    { value: 'MY', label: 'MALAYSIAN' },
    { value: 'MZ', label: 'MOZAMBICAN' },
    { value: 'NA', label: 'NAMIBIAN' },
    { value: 'NC', label: 'NEW CALEDONIA' },
    { value: 'NE', label: 'NIGER' },
    { value: 'NG', label: 'NIGERIAN' },
    { value: 'NI', label: 'NICARAGUAN' },
    { value: 'NL', label: 'DUTCH' },
    { value: 'NO', label: 'NORWEGIAN' },
    { value: 'NP', label: 'NEPALESE' },
    { value: 'NR', label: 'NAURUAN' },
    { value: 'NT', label: 'NETHERLANDS' },
    { value: 'NU', label: 'NIUEAN' },
    { value: 'NZ', label: 'NEW ZEALANDER' },
    { value: 'OM', label: 'OMANI' },
    { value: 'PA', label: 'PANAMANIAN' },
    { value: 'PC', label: 'PACIFIC ISLAND TRUST TERRITORY' },
    { value: 'PE', label: 'PERUVIAN' },
    { value: 'PF', label: 'FRENCH POLYNESIAN' },
    { value: 'PG', label: 'PAPUA NEW GUINEAN' },
    { value: 'PH', label: 'FILIPINO' },
    { value: 'PI', label: 'PITCAIRN' },
    { value: 'PK', label: 'PAKISTANI' },
    { value: 'PL', label: 'POLISH' },
    { value: 'PN', label: 'PALESTINIAN' },
    { value: 'PR', label: 'PUERTO RICAN' },
    { value: 'PT', label: 'PORTUGUESE' },
    { value: 'PW', label: 'PALAUAN' },
    { value: 'PY', label: 'PARAGUAYAN' },
    { value: 'QA', label: 'QATARI' },
    { value: 'RE', label: 'REUNIONESE' },
    { value: 'RF', label: 'RUSSIAN' },
    { value: 'RO', label: 'ROMANIAN' },
    { value: 'RS', label: 'SERBIAN' },
    { value: 'RW', label: 'RWANDAN' },
    { value: 'SA', label: 'SAUDI ARABIAN' },
    { value: 'SB', label: 'SOLOMON ISLANDER' },
    { value: 'SC', label: 'SEYCHELLOIS' },
    { value: 'SD', label: 'SUDANESE' },
    { value: 'SE', label: 'SWEDISH' },
    { value: 'SG', label: 'SINGAPORE CITIZEN' },
    { value: 'SI', label: 'SLOVENIAN' },
    { value: 'SK', label: 'SLOVAK' },
    { value: 'SL', label: 'SIERRA LEONE' },
    { value: 'SM', label: 'SAMMARINESE' },
    { value: 'SN', label: 'SENEGALESE' },
    { value: 'SO', label: 'SOMALI' },
    { value: 'SR', label: 'SURINAMER' },
    { value: 'SS', label: 'STATELESS' },
    { value: 'ST', label: 'SAO TOMEAN' },
    { value: 'SU', label: 'RUSSIAN' },
    { value: 'SV', label: 'SALVADORAN' },
    { value: 'SW', label: 'KITTIAN & NEVISIAN' },
    { value: 'SX', label: 'SOUTH SUDANESE' },
    { value: 'SY', label: 'SYRIAN' },
    { value: 'SZ', label: 'SWAZI' },
    { value: 'TD', label: 'CHADIAN' },
    { value: 'TE', label: 'TIMORENSE' },
    { value: 'TF', label: 'FRENCE SOUTHERN TERRITORIES' },
    { value: 'TG', label: 'TOGOLESE' },
    { value: 'TH', label: 'THAI' },
    { value: 'TI', label: 'TAJIKISTANI' },
    { value: 'TJ', label: 'TADZHIK' },
    { value: 'TK', label: 'TOKELAUAN' },
    { value: 'TM', label: 'TURKMEN' },
    { value: 'TN', label: 'TUNISIAN' },
    { value: 'TO', label: 'TONGAN' },
    { value: 'TP', label: 'EAST TIMORESE' },
    { value: 'TR', label: 'TURK' },
    { value: 'TT', label: 'TRINIDADIAN & TOBAGONIAN' },
    { value: 'TV', label: 'TUVALU' },
    { value: 'TW', label: 'TAIWANESE' },
    { value: 'TZ', label: 'TANZANIAN' },
    { value: 'UG', label: 'UGANDAN' },
    { value: 'UK', label: 'BRITISH SUBJECT' },
    { value: 'UN', label: 'UNKNOWN' },
    { value: 'UR', label: 'UKRAINIAN' },
    { value: 'US', label: 'AMERICAN' },
    { value: 'UY', label: 'URUGUAYAN' },
    { value: 'UZ', label: 'UZBEKISTAN' },
    { value: 'VA', label: 'VATICAN CITY STATE (HOLY SEE)' },
    { value: 'VC', label: 'ST. VINCENTIAN' },
    { value: 'VE', label: 'VENEZUELAN' },
    { value: 'VN', label: 'VIETNAMESE' },
    { value: 'VU', label: 'NI-VANUATU' },
    { value: 'WF', label: 'WALLIS AND FUTUNA ISLANDERS' },
    { value: 'WS', label: 'SAMOAN' },
    { value: 'XB', label: 'REFUGEE (XXB)' },
    { value: 'XC', label: 'REFUGEE (OTHER THAN XXB)' },
    { value: 'XX', label: 'OTHERS' },
    { value: 'YD', label: 'YEMEN, SOUTH' },
    { value: 'YE', label: 'YEMEN ARAB REP' },
    { value: 'YM', label: 'YEMENI' },
    { value: 'YU', label: 'YUGOSLAVIANS' },
    { value: 'ZA', label: 'SOUTH AFRICAN' },
    { value: 'ZM', label: 'ZAMBIAN' },
    { value: 'ZR', label: 'ZAIRAN' },
    { value: 'ZW', label: 'ZIMBABWEAN' }
  ];

  export default Nationalities;